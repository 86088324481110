<app-header></app-header>
<style>
    tbody {
    display:block;
    max-height:195px;
    overflow:auto;
}
thead, tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;
}
label {
    margin-bottom: 0;
}
.progress {
    margin-top: 1rem;
}
.table tbody tr td .remove-file{
    display: none;
}
.table tbody tr td:hover .remove-file{
    display: block;
    margin-top: -7px;
    float: right;
}
.upload-success{
    float: right;
    font-size: 22px;
    margin-top: -11px;
}
.upload-error{
    float: right;
    margin-top: -11px;
    font-size: 22px;
}
.upload-progress{
    float: right;
    margin-top: -11px;
    font-size: 22px;
}
</style>
<main>
  <!-- Spotlight -->
  <section class="slice slice-xl pb-200 bg-dark">
      <div class="container">
          <div class="row row-grid">
              <div class="col-lg-5">
                  <div class="card shadow">
                      <div class="card-header py-4">
                          <div class="d-flex align-items-center">
                              <div class="avatar-content">
                                  <h5 class="h6 mb-0">Order Files</h5>
                                  <!-- <small class="d-block text-muted">to optimize your workflow create account</small> -->
                              </div>
                          </div>
                      </div>
                      <div class="card-body">
                          <form role="form" class="form-validate" role="form" name="UploadFileForm" method="POST" novalidate="">
                              <div class="form-group">
                                <span>Email</span><br>
                                <span>{{CustomerEmail}}</span>
                              </div>
                              <div class="form-group">
                                <span>Name</span><br>
                                <span>{{CustomerName}}</span>
                              </div>
                              <div class="form-group">
                                <span>Order Title</span><br>
                                <span>{{ProjectName}}</span>
                              </div>
                              <div class="form-group">
                                <span>Instructions</span><br>
                                <span>{{Instructions}}</span>
                              </div>
                              <div class="form-group">
                                  <div class="table-responsive">
                                      <table class="table table-sm table-flush align-items-center align-items-center">
                                          <tbody>
                                              <tr *ngFor="let item of FilesList">
                                                  <td style="padding-left:0; line-height: 16px;">
                                                      <span class="font-weight-bold text-xs" style="color: #3e3d3d;">{{ item.FileName }}</span>
                                                      <div class="clearfix"></div>
                                                      <span class="text-xs">{{ item.FileSize/1024/1024 | number:'.2' }} MB </span>
                                                  </td>
                                              </tr>
                                          </tbody>
                                      </table>
                                  </div>
                              </div>
                              <div class="row align-items-center">
                                  <div class="col-sm-7" >
                                    <input type="hidden" name="Token" id="Token" value="{{TokenValue}}">
                                    <button type="submit" class="btn btn-dark mb-3 mb-sm-0" (click)="downloadAllFiles();">Download</button>
                                  </div>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
              <div class="col-lg-7">
                      <h2 class="h2 mb-4 text-white">What you have to do</h2>
                      <p>It’s simple. Upload your images via WeTransfer, Dropbox or Google drive to the ID <a class="text-white" href="mailto:upload@imageingine.com">upload@imageingine.com</a> 
                          and download the processed images from the same platform. Everything in between this will be taken care by us.</p>
                      <p>Our direct upload functionality is under construction and the above mentioned
                          third-party tools are just temporary. Once Imageingine’s parts are built, your uploads
                          and downloads can be done directly via our portal without any third-party tools. We
                          apologize for this inconvenience.</p>
                      <p>Our direct upload functionality is coming SOON!<br> Feel free to mail us with your
                          queries at <a class="text-white" href="mailto:upload@imageingine.com">upload@imageingine.com</a></p>
              </div>
          </div>
      </div>
  </section>
</main>


<app-footer></app-footer>