<style>
    #BeforeAfterAction{
        display: none !important;
    }
    </style>
<app-header></app-header>
<main ondragstart="return false;" ondrop="return false;">
    <!-- Spotlight -->
    <div id="VSbody">
        <section class="slice slice-lg bg-cover bg-size--cover overflow-hidden" style="background-position: bottom; background-image: url(/assets/img/services/virtual-staging/virtual-staging-01-edited.jpg);">
            <span class="mask bg-gradient-primary opacity-9"></span>
            <div class="section-inner bg-gradient-primary">
                <span class="section-inner-bg"></span>
            </div>
            <div class="container py-sm">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                            <div class="pt-lg-lg">
                        <h1 class="heading display-4 font-weight-300 text-white lh-110 mb-4">Virtual Staging</h1>
                        <p class="lead text-white lh-180">Digitally furnish vacant photographs. Includes adding furniture to all rooms shown in your photo. </p>
                        <div class="mt-5">
                            <a href="#gallery" class="btn btn-white btn-sm rounded-pill hover-scale-110 btn-icon mr-sm-4 scroll-me" data-scroll-to-offset="0">
                                <span class="btn-inner--text">View gallery</span>
                                <span class="btn-inner--icon">
                                    <i class="fas fa-angle-right"></i>
                                </span>
                            </a>
                            <a [routerLink]="['/upload-now']" class="btn btn-outline-white btn-icon rounded-pill hover-scale-110 btn-sm px-4">
                                    <span class="btn-inner--icon"><i class="fas fa-play"></i></span>
                                    <span class="btn-inner--text">try us for free</span>
                                </a>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
       
        <!-- Image + Content -->
        <section class="slice slice-lg bg-white" id="gallery">
            <div class="container">
                <div class="mb-md pb-5">
                    <h3 class="h2">The new standard in home staging</h3>
                    <div>
                        <p class="lead">Virtual staging is the easiest way to make your properties stand out from the competition and <br>help buyers fall in love with their next home.</p>
                    </div>
                </div>
                <div class="row row-grid align-items-center">
                    <div class="col-lg-4">
                        <div class="d-flex align-items-start mb-5">
                            <div class="pr-4">
                                <div class="icon bg-gradient-primary text-white rounded-circle icon-lg icon-shape shadow">
                                    <i class="fa fa-couch"></i>
                                </div>
                            </div>
                            <div class="icon-text">
                                <h5 class="h5">Digitally furnish</h5>
                                <p class="mb-0">Digitally furnish vacant images by adding furniture.</p>
                            </div>
                        </div>
                        <div class="d-flex align-items-start mb-5">
                            <div class="pr-4">
                                <div class="icon bg-gradient-primary text-white rounded-circle icon-lg icon-shape shadow">
                                    <i class="fa fa-stopwatch"></i>
                                </div>
                            </div>
                            <div class="icon-text">
                                <h5 class="">Quick Turnaround Time</h5>
                                <p class="mb-0">1-3 business days. Varies based on the plan you choose.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="d-flex align-items-start mb-5">
                            <div class="pr-4">
                                <div class="icon bg-gradient-primary text-white rounded-circle icon-lg icon-shape shadow">
                                    <i class="fa fa-dollar-sign"></i>
                                </div>
                            </div>
                            <div class="icon-text">
                                <h5 class="">Lowest Pricing</h5>
                                <p class="mb-0">Our Promise. We guarantee you the LOWEST PRICE on Virtual Staging.</p>
                            </div>
                        </div>
                        <div class="d-flex align-items-start mb-5">
                            <div class="pr-4">
                                <div class="icon bg-gradient-primary text-white rounded-circle icon-lg icon-shape shadow">
                                    <i class="fa fa-check-double"></i>
                                </div>
                            </div>
                            <div class="icon-text">
                                <h5 class="">Free Revisions</h5>
                                <p class="mb-0">Our free sample ensures you’re satisfied with the work we do.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="d-flex align-items-start mb-5">
                            <div class="pr-4">
                                <div class="icon bg-gradient-primary text-white rounded-circle icon-lg icon-shape shadow">
                                    <i class="fa fa-smile"></i>
                                </div>
                            </div>
                            <div class="icon-text">
                                <h5 class="">Satisfaction Guaranteed</h5>
                                <p class="mb-0">If you don't love your final photos we'll give you a full refund.</p>
                            </div>
                        </div>
                        <div class="d-flex align-items-start mb-5">
                            <div class="pr-4">
                                <div class="icon bg-gradient-primary text-white rounded-circle icon-lg icon-shape shadow">
                                    <i class="fa fa-images"></i>
                                </div>
                            </div>
                            <div class="icon-text">
                                <h5 class="">Free Samples</h5>
                                <p class="mb-0">If you don't love your final photos we'll give you a full refund.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                    <div class="code-example">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-sm-12 my-4">
                                <a href="../assets/img/services/virtual-staging/virtual-staging-01.{{ImageExtension}}" data-fancybox="group">
                                    <img src="../assets/img/services/virtual-staging/thumbs/virtual-staging-01-thumb.{{ImageExtension}}" class="img-fluid rounded" alt="01"/>
                                </a>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-12 my-4">
                                <a href="../assets/img/services/virtual-staging/virtual-staging-01-edited.{{ImageExtension}}" data-fancybox="group">
                                    <img src="../assets/img/services/virtual-staging/thumbs/virtual-staging-01-edited-thumb.{{ImageExtension}}" class="img-fluid rounded" alt="01"/>
                                </a>
                            </div>
                            <div class="col-lg-4 my-4">
                                <div class="card shadow-sm shadow--hover overflow-hidden">
                                    <img alt="Image placeholder" src="../assets/img/services/virtual-staging/thumbs/virtual-staging-01-edited-thumb.jpg" class="card-img-top">
                                    <span class="mask bg-blue opacity-9" style="background-color: #656565!important"></span>
                                    <span class="mask mask--hover bg-gradient-blue opacity-8"></span>
                                    <div class="card-img-overlay d-flex flex-column">
                                        <div class="my-auto justify-content-center text-center">
                                            
                                            <a class="h3 text-white">livingarea</a>
                                            <p class="lead lh-150 text-white mt-4">No matter what he does, every person on earth plays a central role in the history.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 my-4">
                                <div class="card shadow-sm shadow--hover overflow-hidden">
                                    <img alt="Image placeholder" src="../assets/img/services/virtual-staging/thumbs/virtual-staging-02-edited-thumb.jpg" class="card-img-top">
                                    <span class="mask bg-blue opacity-9" style="background-color: #866c54!important"></span>
                                    <span class="mask mask--hover bg-gradient-blue opacity-8"></span>
                                    <div class="card-img-overlay d-flex flex-column">
                                        <div class="my-auto justify-content-center text-center">
                                            
                                            <a class="h3 text-white">exteriors</a>
                                            <p class="lead lh-150 text-white mt-4">No matter what he does, every person on earth plays a central role in the history.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-12 my-4">
                                <a href="../assets/img/services/virtual-staging/virtual-staging-02.{{ImageExtension}}" data-fancybox="group">
                                    <img src="../assets/img/services/virtual-staging/thumbs/virtual-staging-02-thumb.{{ImageExtension}}" class="img-fluid rounded" alt="01"/>
                                </a>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-12 my-4">
                                <a href="../assets/img/services/virtual-staging/virtual-staging-02-edited.{{ImageExtension}}" data-fancybox="group">
                                    <img src="../assets/img/services/virtual-staging/thumbs/virtual-staging-02-edited-thumb.{{ImageExtension}}" class="img-fluid rounded" alt="01"/>
                                </a>
                            </div>
                            
                            <div class="col-lg-4 col-md-4 col-sm-12 my-4">
                                <a href="../assets/img/services/virtual-staging/virtual-staging-03.{{ImageExtension}}" data-fancybox="group">
                                    <img src="../assets/img/services/virtual-staging/thumbs/virtual-staging-03-thumb.{{ImageExtension}}" class="img-fluid rounded" alt="01"/>
                                </a>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-12 my-4">
                                <a href="../assets/img/services/virtual-staging/virtual-staging-03-edited.{{ImageExtension}}" data-fancybox="group">
                                    <img src="../assets/img/services/virtual-staging/thumbs/virtual-staging-03-edited-thumb.{{ImageExtension}}" class="img-fluid rounded" alt="01"/>
                                </a>
                            </div>
                            <div class="col-lg-4 my-4">
                                <div class="card shadow-sm shadow--hover overflow-hidden">
                                    <img alt="Image placeholder" src="../assets/img/services/virtual-staging/thumbs/virtual-staging-03-edited-thumb.jpg" class="card-img-top">
                                    <span class="mask bg-blue opacity-9" style="background-color: #7d7f83!important"></span>
                                    <span class="mask mask--hover bg-gradient-blue opacity-8"></span>
                                    <div class="card-img-overlay d-flex flex-column">
                                        <div class="my-auto justify-content-center text-center">
                                            
                                            <a class="h3 text-white">bedroom</a>
                                            <p class="lead lh-150 text-white mt-4">No matter what he does, every person on earth plays a central role in the history.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
            </div>
        </section>
        <app-service-features></app-service-features>
    </div>
</main>
<app-footer></app-footer>