<app-header></app-header>
<main>
    <!-- Spotlight -->
    <section class="slice slice-xl section-rotate d-flex align-items-center  bg-secondary">
        <div class="section-inner bg-gradient-primary">
            <span class="section-inner-bg"></span>
        </div>
        <div class="container py-sm">
            <div class="row align-items-center">
                <div class="col-lg-5">
                    <h1 class=" text-white mb-3">Image Enhancement</h1>
                    <p class="lead text-white lh-180">Ready to advertise, ready to sell. We will deliver compelling and market-ready images with unparalleled
                        standards. You can access these from any device of choice.</p>
                    <div class="mt-5">
                        <a href="#gallery" class="btn btn-white btn-circle btn-sm btn-translate--hover btn-icon mr-sm-4 scroll-me" data-scroll-to-offset="0">
                            <span class="btn-inner--text">View gallery</span>
                            <span class="btn-inner--icon">
                                <i class="fas fa-angle-right"></i>
                            </span>
                        </a>
                    </div>
                </div>
                <div class="col-lg-7 ml-lg-auto">
                    <img src="../assets/img/prv/macbook-pro-clay.png" class="img-fluid d-none d-lg-block">
                </div>
            </div>
        </div>
    </section>
    <section class="slice slice-xl bg-secondary" style="padding-top: 0">
        <div class="container">
            <div class="row row-grid ">
                <div class="col-md-12 col-lg-12  ml-lg-auto ">
                    <div class="pr-md-4 ">
                        <h3 class="heading h3">Image Enhancement</h3>
                        <p class="lead text-gray my-4">The reputation of any business depends on the quality of the images that are being published. A flawless
                            and appealing image needs very precise editing. We at Imageingine have the skill and expertise
                            to deliver exactly that. Be it for the real estate sector, e-commerce sector, F&B sector, or
                            even a personal portfolio, our image enhancement services will ensure to produce industry-ready
                            images of exceptional quality. Some of the tasks that we carry out for image enhancement include:</p>
                        <ul>
                            <li>Colour alteration, accurate sharpness and brightness, appropriate filters</li>
                            <li>Retouching of images</li>
                            <li>Bringing focus to the main subject/object </li>
                            <li>Background correction and many more</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Image + Content -->
    <app-service-features></app-service-features>
    <section class="slice slice-xl bg-secondary" id="gallery">
        <div class="container">
            <div class="row row-grid justify-content-center">
                <div class="col-md-12 col-lg-12  ml-lg-auto ">
                    <div class="pr-md-4 text-center">
                        <h3 class="heading h3">Our edits</h3>
                        <p class="lead text-gray my-4">Create lead generating, attention grabbing real estate images designed to compel sales quickly &amp;
                            professionally. </p>
                    </div>
                </div>
            </div>
            <div class="row row-grid">
                <div class="code-example">
                    <div class="row">
                        <div class="col-4 my-4">
                            <a href="../assets/img/prv/img-1-800x600.jpg" data-fancybox="group" data-caption="My caption">
                                <img src="../assets/img/prv/img-1-800x600.jpg" class="img-fluid rounded" />
                            </a>
                        </div>
                        <div class="col-4 my-4">
                            <a href="../assets/img/prv/img-2-800x600.jpg" data-fancybox="group" data-caption="My caption">
                                <img src="../assets/img/prv/img-2-800x600.jpg" class="img-fluid rounded" />
                            </a>
                        </div>
                        <div class="col-4 my-4">
                            <a href="../assets/img/prv/img-1-800x600.jpg" data-fancybox="group" data-caption="My caption">
                                <img src="../assets/img/prv/img-1-800x600.jpg" class="img-fluid rounded" />
                            </a>
                        </div>
                        <div class="col-4 my-4">
                            <a href="../assets/img/prv/img-1-800x600.jpg" data-fancybox="group" data-caption="My caption">
                                <img src="../assets/img/prv/img-1-800x600.jpg" class="img-fluid rounded" />
                            </a>
                        </div>
                        <div class="col-4 my-4">
                            <a href="../assets/img/prv/img-2-800x600.jpg" data-fancybox="group" data-caption="My caption">
                                <img src="../assets/img/prv/img-2-800x600.jpg" class="img-fluid rounded" />
                            </a>
                        </div>
                        <div class="col-4 my-4">
                            <a href="../assets/img/prv/img-1-800x600.jpg" data-fancybox="group" data-caption="My caption">
                                <img src="../assets/img/prv/img-1-800x600.jpg" class="img-fluid rounded" />
                            </a>
                        </div>
                        <div class="col-4 my-4">
                            <a href="../assets/img/prv/img-1-800x600.jpg" data-fancybox="group" data-caption="My caption">
                                <img src="../assets/img/prv/img-1-800x600.jpg" class="img-fluid rounded" />
                            </a>
                        </div>
                        <div class="col-4 my-4">
                            <a href="../assets/img/prv/img-2-800x600.jpg" data-fancybox="group" data-caption="My caption">
                                <img src="../assets/img/prv/img-2-800x600.jpg" class="img-fluid rounded" />
                            </a>
                        </div>
                        <div class="col-4 my-4">
                            <a href="../assets/img/prv/img-1-800x600.jpg" data-fancybox="group" data-caption="My caption">
                                <img src="../assets/img/prv/img-1-800x600.jpg" class="img-fluid rounded" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<app-footer></app-footer>