import { Component, OnInit } from '@angular/core';
import { Title, Meta }     from '@angular/platform-browser';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { CustomValidators } from 'ng2-validation';
import { HttpClientModule, HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';

//const URL = 'http://localhost:3000/UploadFiles';
const URL = 'https://imageingine-upload.herokuapp.com/UploadFiles';

@Component({
  selector: 'app-upload-now',
  templateUrl: './upload-now.component.html',
  styleUrls: ['./upload-now.component.css']
})
export class UploadNowComponent implements OnInit {
  UploadFileForm: FormGroup;
  OrderTitle : any; UserName : any; UserEmail : any; Instructions : any; 
  allowedMimeType : any; UploadFile: any; Trensferring:boolean; 
  TransferButton: boolean; TransferringButton : boolean;
  constructor(private titleService: Title,private meta: Meta, private fb: FormBuilder, private http: HttpClient) {
    this.UploadFileForm = fb.group({
      OrderTitle : ['',Validators.required], UserName : ['', ''], 
      UserEmail : ['', Validators.compose([Validators.required, CustomValidators.email])], Instructions : ['', ''], 
      UploadFile : ['', Validators.required]
    })
    this.allowedMimeType = ['image/png', 'video/mp4', 'image/jpeg', 'image/cr2'];
    this.Trensferring = true; this.TransferButton = true; this.TransferringButton = false;
  }
  
  public uploader:FileUploader = new FileUploader({url: URL, allowedMimeType: this.allowedMimeType});
  public hasBaseDropZoneOver:boolean = false;
  public hasAnotherDropZoneOver:boolean = false;
 
  public fileOverBase(e:any):void {
    this.hasBaseDropZoneOver = e;
  }
 
  public fileOverAnother(e:any):void {
    this.hasAnotherDropZoneOver = e;
  }

  ngOnInit() {
    this.uploader.onAfterAddingFile = (file)=> { file.withCredentials = false; };
    this.uploader.onCompleteItem = (item:any, response:any, status:any, headers:any) => {};
  }

  bytesToSize(bytes) {
    let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    let convertedbyt:any  = Math.floor(Math.log(bytes) / Math.log(1024));
    let intvalue = parseInt(convertedbyt);
    let convertmb:any =((bytes/Math.pow(1024,intvalue))).toFixed(2)+' '+sizes[intvalue];
    return convertmb;
 };

  sizevalue:any = 0;
  manageUploadFileForm($ev,value){
    $ev.preventDefault();
    for (let c in this.UploadFileForm.controls) { this.UploadFileForm.controls[c].markAsTouched(); }
    if(this.UploadFileForm.valid){
      this.sizevalue = 0;
      let lengthvalue = this.uploader.queue.length;
      for(let i=0; i<lengthvalue;i++){
        this.sizevalue+= this.uploader.queue[i].file.size;
      }
      let TotalFileSize = this.bytesToSize(this.sizevalue);
      let NoOfImages = this.uploader.queue.length;
      this.Trensferring = false;
      this.TransferButton = false; this.TransferringButton = true;
      this.uploader.onBuildItemForm = (fileItem : any, form :any) => {
        form.append('OrderTitle' , value.OrderTitle);
        form.append('UserName' , value.UserName);
        form.append('UserEmail' , value.UserEmail);
        form.append('Instructions' , value.Instructions);
        form.append('NoOfImages' , NoOfImages);
        form.append('TotalFileSize' , TotalFileSize);
      }
      this.uploader.uploadAll();
      this.uploader.onSuccessItem = (item: any, response: string, status: number, headers: any): any => {
        if(response){
          this.UploadFileForm.reset();
          //this.uploader.removeFromQueue;
          this.Trensferring = true;
          this.TransferButton = true; this.TransferringButton = false;
        }else{
          console.log("response"+JSON.stringify(response));
          console.log("Error in Uploading files ");
        }
      }
    }
  }
}

