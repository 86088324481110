import { Component, OnInit } from '@angular/core';
import { Title, Meta }     from '@angular/platform-browser';
import * as  Globals  from '../global';

@Component({
  selector: 'app-beauty-portrait-retouching',
  templateUrl: './beauty-portrait-retouching.component.html',
  styleUrls: ['./beauty-portrait-retouching.component.css']
})
export class BeautyPortraitRetouchingComponent implements OnInit {
  ImageExtension: any;
  constructor(private titleService: Title,private meta: Meta) { 
    this.ImageExtension = Globals.getBrowserExtension();
  }

  ngOnInit() {
    // this.titleService.setTitle("Beauty & Portrait Retouching");
  }

}
