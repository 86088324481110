import { Component, OnInit } from '@angular/core';
import { Title, Meta }     from '@angular/platform-browser';
import * as  Globals  from '../global';
@Component({
  selector: 'app-automobile-retouching',
  templateUrl: './automobile-retouching.component.html',
  styleUrls: ['./automobile-retouching.component.css']
})
export class AutomobileRetouchingComponent implements OnInit {
  ImageExtension: any;
  constructor(private titleService: Title,private meta: Meta) {
    this.ImageExtension = Globals.getBrowserExtension();
   }

  ngOnInit() {
    // this.titleService.setTitle("Automobile Retouching");
  }

}
