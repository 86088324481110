<app-header></app-header>
<main ondragstart="return false;" ondrop="return false;">
    <!-- Spotlight -->
    <div id="BPRbody">
        <section class="slice slice-lg bg-cover bg-size--cover overflow-hidden" style="background-position: center; background-image: url(/assets/img/services/beauty-portrait-retouching-thumb.jpg);">
            <span class="mask bg-gradient-warning opacity-9"></span>
            <div class="section-inner bg-gradient-primary">
                <span class="section-inner-bg"></span>
            </div>
            <div class="container py-sm">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                            <div class="pt-lg-lg pb-lg-md">
                        <h1 class="heading display-4 font-weight-300 text-white lh-110 mb-4">beauty & portrait retouching</h1>
                        <p class="lead text-white lh-180">get that perfect portfolio shot with us. our team works on standard editing projects and those that require advance editing with the same zeal and dedication. </p>
                        <div class="mt-5">
                            <a href="#gallery" class="btn btn-white rounded-pill btn-circle btn-sm btn-translate--hover btn-icon mr-sm-4 scroll-me" data-scroll-to-offset="0">
                                <span class="btn-inner--text">View gallery</span>
                                <span class="btn-inner--icon">
                                    <i class="fas fa-angle-right"></i>
                                </span>
                            </a>
                            <a [routerLink]="['/upload-now']" class="btn rounded-pill btn-outline-white btn-icon btn-circle btn-sm px-4">
                                    <span class="btn-inner--icon"><i class="fas fa-play"></i></span>
                                    <span class="btn-inner--text">try us for free</span>
                                </a>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="slice slice-lg bg-secondary">
            <div class="container">
                <div class="row row-grid ">
                    <div class="col-md-12 col-lg-12  ml-lg-auto ">
                        <div class="pr-md-4">
                            <h1 class="heading h1">beauty & portrait retouching</h1>
                            <p class="lead text-gray my-4">we believe in retaining the originality of any picture. our beauty retouching services will be such that, the essence of the original portrait will remain, with only the required enhancements made. of course, our client’s vision is equally important. if you want to transport a modern-age image to the 80’s era, we will do so. the portrait retouching performed by our team will be an exact replica of your visualization. from sleek and modish looks to the vintage feel, and for commercial images, as well as, personal images, we deliver our retouching services with impeccable quality.</p>
                            <p><b>some of our beauty & portrait retouching services include, but are not restricted to:</b></p>
                            <ul><li>Basic retouching: cropping, removing blemishes, contrast, brightness, etc.</li><li>Adding textures, effects and creating a statement portrait</li><li>Wedding, couple, family, baby and personal portrait retouching and enhancement</li></ul>
                        </div>
                    </div>
                </div>
            </div>
            <a href="#gallery" class="tongue tongue-bottom scroll-me"><i class="fas fa-angle-down"></i></a>
        </section>
        <!-- Image + Content -->
        <section class="slice slice-xl bg-white" id="gallery">
            <div class="container">
                <div class="row row-grid justify-content-center">
                    <div class="col-md-12 col-lg-12  ml-lg-auto ">
                        <div class="pr-md-4">
                            <h3 class="heading h1">our edits</h3>
                            <p class="lead text-gray my-4">get that perfect portfolio shot with us. our team works on standard editing projects and those that require advance editing with the same zeal and dedication.</p>
                        </div>
                    </div>
                </div>
                    <div class="code-example">
                        we will update soon...
                    </div>
            </div>
        </section>
        <app-service-features></app-service-features>
    </div>
</main>
<app-footer></app-footer>