import { NgModule } from '@angular/core';
import { RouterModule, Routes, Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import { Title, Meta } from '@angular/platform-browser';
import { routes } from "./routes";

@NgModule({
    imports: [ RouterModule.forRoot(routes) ],
    exports: [ RouterModule ]
})

export class RoutersModule {
    Tags: any;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private metaService: Meta
      ){
        this.router.events
        .filter(event => event instanceof NavigationEnd)
        .map(() => this.activatedRoute)
        .map(route => {
            while (route.firstChild) route = route.firstChild;
            return route;
        })
        .filter(route => route.outlet === 'primary')
        .mergeMap(route => route.data)
        .subscribe((event) => {
            this.titleService.setTitle(event['title']);
            this.Tags = { name: 'description', content: event['metaDescription'] };
            let attributeSelector : string = 'name="description"';
            this.metaService.removeTag(attributeSelector);
            this.metaService.addTags([
                this.Tags
            ]);
        });
    }
}