<!-- Clients -->
<section class="slice"  id="sct_features">
        <div class="container">
            <div class="text-center">
                <h3 class="h2">trusted by over <span class="text-primary">5000+</span> clients</h3>
                <div class="fluid-paragraph mt-3">
                    <p class="lead">start modifying your images and videos with us to make them <br/>market-ready in no time</p>
                </div>
            </div>
        </div>
    </section>
<!-- Call to action -->
<section class="slice slice-lg">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="card bg-dark rounded shadow-lg border-0 text-center position-relative zindex-100">
                        <div class="card-body p-5">
                            <h3 class="text-white font-weight-400">start a new project with imageingine</h3>
                            <p>with laser sharp focus on quality & value, we aim to deliver cost-effective solutions, with our clients’ interests as our goal</p>
                            <div class="mt-4">
                                <a [routerLink]="['/upload-now']" class="btn btn-primary rounded-pill hover-scale-110 px-4">upload now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <footer class="footer footer-dark bg-gradient-primary footer-dark footer-rotate bg-gradient-primary">
            <div class="container">
                <div class="row pt-md">
                    <div class="col-lg-4 mb-5 mb-lg-0">
                        <a class="navbar-brand mr-lg-5 form-group">
                            <img alt="Image placeholder" src="./assets/img/brand/imageingine-logo.svg" style="height: 45px;">
                        </a>
                        <p class="text-sm">we indulge in the entire gamut of photo and video editing, aiming to optimize the workflow to minimize time while maintaining the high quality.</p>
                    </div>
                    <div class="col-lg-3 col-sm-4 col-xs-12 mb-5 mb-lg-0">
                        <ul class="list-unstyled text-small">
                            <li><a [routerLink]="['/realestate-image-processing']">real estate image processing</a>
                            </li>
                            <li><a [routerLink]="['/beauty-portrait-retouching']">Beauty &amp; Portrait Retouching</a>
                            </li>
                            <li><a [routerLink]="['/ecommerce-image-processing']">ecommerce image processing</a>
                            </li>
                            <li><a [routerLink]="['/image-clipping-path']">image clipping path</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-2 col-6 col-sm-4 ml-lg-auto mb-5 mb-lg-0">
                            <ul class="list-unstyled">
                            <li><a [routerLink]="['/about']">About us</a>
                            </li>
                            <li><a [routerLink]="['/contact']">Contact us</a>
                            </li>
                            <li><a [routerLink]="['/upload-now']">Uploads help</a>
                            </li>
                            <li><a [routerLink]="['/contact']">offers area</a>
                            </li>
                            
                        </ul>
                    </div>
                    
                    <div class="col-lg-2 col-6 col-sm-4 mb-5 mb-lg-0">
                        <ul class="list-unstyled">
                            <li><a href="#">Terms &amp; Conditions</a>
                            </li>
                            <li><a href="#">Privacy Policy</a>
                            </li>
                            <li><a [routerLink]="['/contact']">Support</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row align-items-center justify-content-md-between py-4 mt-4 delimiter-top">
                    <div class="col-md-6">
                        <div class="copyright text-sm font-weight-bold text-center text-md-left">
                            &copy; 2019 <a href="https://imageingine.com/" class="font-weight-bold" target="_blank" >imageingine</a>. All rights reserved.
                        </div>
                    </div>
                    <div class="col-md-6">
                        <ul class="nav justify-content-center justify-content-md-end mt-3 mt-md-0">
                            <li class="nav-item">
                                <a class="nav-link" href="https://www.facebook.com/imageingine" target="_blank">
                                    <i class="fab fa-facebook"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
       
