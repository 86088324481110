<app-header></app-header>
<main>
  <!-- Spotlight -->
  <section class="slice slice-lg bg-cover bg-size--cover" data-spotlight="fullscreen" style="background-image: url(../assets/img/backgrounds/img-5.jpg); background-position: center 30%; background-repeat: no-repeat; background-size: auto;">
      <span class="mask bg-gradient-primary alpha-6"></span>
      <div class="container-fluid">
          <div class="row">
              <div class="col-lg-12">
                  <div class="pt-lg-xl pb-lg-md text-center">
                      <h3 class="heading h4 text-white text-uppercase font-weight-400 mb-0" style="font-family: 'Oswald', sans-serif;">imageingine's</h3>
                      <h2 class="heading display-1 font-weight-600 text-white text-uppercase mb-2" style="font-family: 'Oswald', sans-serif;">STUDIO</h2>
                  </div>
              </div>
          </div>
      </div>
  </section>
  <section class="slice pb-lg bg-secondary">
      <div class="container">
          <div class="row justify-content-center mt--150">
              <div class="col-lg-6">
                  <div class="text-center">
                      <h4 class="h6 text-white text-uppercase mb-3">Choose below</h4>
                  </div>
                  <div class="card-group shadow">
                      <div  class="card {{ImageActiveClass}}" (click)="enableBlocks('Images')">
                          <div class="card-body text-center">
                              <h4 class="h3 text-white mb-0"><i class="fas fa-images mr-3"></i>Images</h4>
                          </div>
                      </div>
                      <div  class="card {{VideoActiveClass}}" (click)="enableBlocks('Videos')">
                          <div class="card-body text-center">
                              <h4 class="h3 text-white mb-0"><i class="fas fa-video mr-3"></i>Videos</h4>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          
      </div>
  
        <div class="container-fluid" *ngIf="ImagesBlock">
            <div class="mb-lg text-center" style="padding-top: 4rem">
                <h3 class="h2 mb-3">Photography</h3>
                <div class="fluid-paragraph text-center">
                   <!-- <p class="lead mb-0">Come look at some of our services. We assure, you will be gratified with our work quality. Every single project of yours, no matter the size, will be delivered with the same quality and  effort from our side.</p> -->
                </div>
            </div>
            <div class="row row-grid">
                
                <div class="col-lg-4">
                        <a [routerLink]="['/fitnessritz-photoshoot']" class="card border-0" data-animate-hover="1">
                                <div class="animate-this">
                                    <img class="rounded z-depth-3 img-fluid" src="../assets/img/prv/fitnessritz-thumb.jpg">
                                    <span class="mask bg-dark alpha-3"></span>
                                    <span class="mask mask--hover bg-gradient-primary alpha-4"></span>
                                    <div class="card-img-overlay d-flex">
                                        <div class="col pt-2">
                                            <h4 class="h3 text-white text-capitalize mb-1">Fitness Ritz Photoshoot</h4>
                                        </div>
                                    </div>
                                </div>
                            </a>
                </div>
                <div class="col-lg-4">
                        <a [routerLink]=""  class="card border-0" data-animate-hover="1">
                                <div class="animate-this">
                                    <img class="rounded z-depth-3 img-fluid" src="../assets/img/prv/coming-soon.jpg">
                                    <span class="mask bg-dark alpha-3"></span>
                                    <span class="mask mask--hover bg-gradient-primary alpha-4"></span>
                                    <div class="card-img-overlay d-flex">
                                        <div class="col pt-2">
                                            <h4 class="h3 text-white text-capitalize mb-1">Nature</h4>
                                        </div>
                                    </div>
                                </div>
                            </a>
                    </div>
                <div class="col-lg-4">
                        <a [routerLink]="" class="card border-0" data-animate-hover="1">
                                <div class="animate-this">
                                    <img class="rounded z-depth-3 img-fluid" src="../assets/img/prv/coming-soon.jpg">
                                    <span class="mask bg-dark alpha-3"></span>
                                    <span class="mask mask--hover bg-gradient-primary alpha-4"></span>
                                    <div class="card-img-overlay d-flex">
                                        <div class="col pt-2">
                                            <h4 class="h3 text-white text-capitalize mb-1">Kids</h4>
                                        </div>
                                    </div>
                                </div>
                            </a>
                </div>
            </div>
            
        </div>

        <div class="container-fluid" *ngIf="VideosBlock">
            <div class="mb-lg text-center" style="padding-top: 4rem">
                <h3 class="h2 mb-3">Videography</h3>
                <div class="fluid-paragraph text-center">
                    <!--<p class="lead mb-0">Come look at some of our services. We assure, you will be gratified with our work quality. Every single project of yours, no matter the size, will be delivered with the same quality and  effort from our side.</p>-->
                </div>
            </div>
            <div class="row row-grid">
                
                <div class="col-lg-4">
                    <div class="card border-0" data-animate-hover="1">
                        <div class="animate-this">
                            <img class="rounded z-depth-3 img-fluid" src="../assets/img/prv/pvvpalli-house.jpg">
                            <a href="https://youtu.be/aqtGJZZbJGc" class="btn btn-lg btn-white btn-icon-only btn-zoom--hover rounded-circle shadow-sm position-absolute right-4 top-4" data-fancybox="">
                                <span class="btn-inner--icon"><i class="fas fa-play"></i></span>
                            </a>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-4">
                        <a [routerLink]=""  class="card border-0" data-animate-hover="1">
                                <div class="animate-this">
                                    <img class="rounded z-depth-3 img-fluid" src="../assets/img/prv/coming-soon.jpg">
                                    <span class="mask bg-dark alpha-3"></span>
                                    <span class="mask mask--hover bg-gradient-primary alpha-4"></span>
                                    <div class="card-img-overlay d-flex">
                                        <div class="col pt-2">
                                            <h4 class="h3 text-white text-capitalize mb-1">Coming soon...</h4>
                                        </div>
                                    </div>
                                </div>
                            </a>
                    </div>
                    <div class="col-lg-4">
                        <a [routerLink]=""  class="card border-0" data-animate-hover="1">
                                <div class="animate-this">
                                    <img class="rounded z-depth-3 img-fluid" src="../assets/img/prv/coming-soon.jpg">
                                    <span class="mask bg-dark alpha-3"></span>
                                    <span class="mask mask--hover bg-gradient-primary alpha-4"></span>
                                    <div class="card-img-overlay d-flex">
                                        <div class="col pt-2">
                                            <h4 class="h3 text-white text-capitalize mb-1">Coming soon...</h4>
                                        </div>
                                    </div>
                                </div>
                            </a>
                    </div>
            </div>
        </div>
    </section>
</main>
<app-footer></app-footer>