import { NgtUniversalModule } from '@ng-toolkit/universal';
import { CommonModule } from '@angular/common';
import { BrowserModule,Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import * as $ from 'jquery';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { routes } from './routes';
import {  RECAPTCHA_SETTINGS,  RecaptchaSettings,  RecaptchaLoaderService,  RecaptchaModule } from 'ng-recaptcha';
import { HttpClientModule, HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { HttpModule } from '@angular/http';

import { FormGroup, FormBuilder, Validators, FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';

import { FileSelectDirective, FileDropDirective, FileUploadModule } from 'ng2-file-upload';
import { RealestateImageProcessingComponent } from './realestate-image-processing/realestate-image-processing.component';
import { FooterComponent } from './footer/footer.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { BeautyPortraitRetouchingComponent } from './beauty-portrait-retouching/beauty-portrait-retouching.component';
import { EcommerceImageProcessingComponent } from './ecommerce-image-processing/ecommerce-image-processing.component';
import { ImageClippingPathComponent } from './image-clipping-path/image-clipping-path.component';
import { VideoEditingComponent } from './video-editing/video-editing.component';
import { ServicesComponent } from './services/services.component';
import { ImageEnhancementComponent } from './image-enhancement/image-enhancement.component';
import { PhotoManipulationComponent } from './photo-manipulation/photo-manipulation.component';
import { PhotoRestorationComponent } from './photo-restoration/photo-restoration.component';
import { AutomobileRetouchingComponent } from './automobile-retouching/automobile-retouching.component';
import { PhotographyVideographyComponent } from './photography-videography/photography-videography.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { UploadNowComponent } from './upload-now/upload-now.component';
import { FitnessritzPhotoshootComponent } from './fitnessritz-photoshoot/fitnessritz-photoshoot.component';
import { ServiceFeaturesComponent } from './service-features/service-features.component';
import { VirtualStagingComponent } from './virtual-staging/virtual-staging.component';

// const globalSettings: RecaptchaSettings = {siteKey: '6Ld-WDsUAAAAAD-Yhjaf_QpUyKf7lZjEltL4eIMH'};
const globalSettings: RecaptchaSettings = {siteKey: '6LfICHIUAAAAAKiHfXFJl39LdsZ3v2qVqhHUNxu_'};
import { RoutersModule } from "./routes.modules";
import { DownloadComponent } from './download/download.component';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json,application/x-www-form-urlencoded;charset=utf-8' ,'Accept' : 'application/json, */*', 'Access-Control-Allow-Origin' : 'https://www.webcoretechnix.com',
      'Access-Control-Allow-Credentials': 'true', 'Access-Control-Allow-Headers': 'Access-Control-*, Origin, X-Requested-With, Content-Type, Accept','Access-Control-Allow-Methods' : 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
      'Allow' : 'GET, POST, PUT, DELETE, OPTIONS, HEAD'
  })
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    RealestateImageProcessingComponent,
    FooterComponent,
    ContactComponent,
    AboutComponent,
    BeautyPortraitRetouchingComponent,
    EcommerceImageProcessingComponent,
    ImageClippingPathComponent,
    VideoEditingComponent,
    ServicesComponent,
    ImageEnhancementComponent,
    PhotoManipulationComponent,
    PhotoRestorationComponent,
    AutomobileRetouchingComponent,
    PhotographyVideographyComponent,
    ComingSoonComponent,
    UploadNowComponent,
    FitnessritzPhotoshootComponent,
    ServiceFeaturesComponent,
    VirtualStagingComponent,
    DownloadComponent
    ],
  imports:[
    CommonModule,
    NgtUniversalModule,
    FileUploadModule,
    HttpClientModule,
    HttpModule,
    RecaptchaModule.forRoot(),
    RouterModule.forRoot(routes,{onSameUrlNavigation: 'reload'}),
    FormsModule,
    ReactiveFormsModule,RoutersModule,
    BrowserModule.withServerTransition({appId: 'imageingine'}),
  ],
  exports: [
    RouterModule
  ],
  providers: [{
    provide: RECAPTCHA_SETTINGS,
    useValue: globalSettings,
  },Title],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
  
}
