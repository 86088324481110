import { detect } from 'detect-browser';
const browser = detect();

export const CheckURL = {
  "ContactURL" : "https://www.webcoretechnix.com/imin-restapis/rest/imageingineContactForm",
  "CheckURL" : "https://www.webcoretechnix.com/imin-restapis/rest/checkLaunched",
}
export function randomString() {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < 50; i++){
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}
export function encryptData(Data){
  let salt = randomString();
  let ValueString = salt +':::'+btoa(Data);
  return btoa(ValueString);
} 
export function decryptData(Data){
  let DcryData = atob(Data);
  let ValueString = DcryData.split(':::');
  return atob(ValueString[1]);
}

export const PageUrls = {
  getorderFiles : "http://192.168.1.10/imin-restapis/rest/getOrderFilesList"
}

export function getBrowserExtension(){
  let ImageExtension;
  switch (browser && browser.name) {
      case 'chrome':
         ImageExtension = "webp";
          break;
      case 'firefox':
          ImageExtension = "jpg";
          break;
      case 'edge':
          ImageExtension = "jpg";
          break;
      default:
          ImageExtension = "jpg";
  }
  return ImageExtension;
}