import { RecaptchaSettings } from 'ng-recaptcha';
import { HttpHeaders } from '@angular/common/http';
// const globalSettings: RecaptchaSettings = {siteKey: '6Ld-WDsUAAAAAD-Yhjaf_QpUyKf7lZjEltL4eIMH'};
var globalSettings = { siteKey: '6LfICHIUAAAAAKiHfXFJl39LdsZ3v2qVqhHUNxu_' };
var httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json,application/x-www-form-urlencoded;charset=utf-8', 'Accept': 'application/json, */*', 'Access-Control-Allow-Origin': 'https://www.webcoretechnix.com',
        'Access-Control-Allow-Credentials': 'true', 'Access-Control-Allow-Headers': 'Access-Control-*, Origin, X-Requested-With, Content-Type, Accept', 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
        'Allow': 'GET, POST, PUT, DELETE, OPTIONS, HEAD'
    })
};
var ɵ0 = globalSettings;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
