<app-header></app-header>
<main ondragstart="return false;" ondrop="return false;">
    <!-- Spotlight -->
    <div id="REIbody">
        <section class="slice slice-lg bg-cover bg-size--cover overflow-hidden" style="background-position: center; background-image: url(/assets/img/backgrounds/realestate-image-editing-from-Imageingine.jpg);">
            <span class="mask bg-gradient-primary opacity-9"></span>
            <div class="section-inner bg-gradient-primary">
                <span class="section-inner-bg"></span>
            </div>
            <div class="container py-sm">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                            <div class="pt-lg-lg pb-lg-md">
                        <h1 class="heading display-4 font-weight-300 text-white lh-110 mb-4">real estate image processing</h1>
                        <p class="lead text-white lh-180">an image is the starting point to selling. our processed real estate images will ensure client’s
                            get a feel of house just from a picture. </p>
                        <div class="mt-5">
                            <a href="#gallery" class="btn btn-white rounded-pill btn-circle btn-sm btn-translate--hover btn-icon mr-sm-4 scroll-me" data-scroll-to-offset="0">
                                <span class="btn-inner--text">View gallery</span>
                                <span class="btn-inner--icon">
                                    <i class="fas fa-angle-right"></i>
                                </span>
                            </a>
                            <a [routerLink]="['/upload-now']" class="btn btn-outline-white rounded-pill btn-icon btn-circle btn-sm px-4">
                                    <span class="btn-inner--icon"><i class="fas fa-play"></i></span>
                                    <span class="btn-inner--text">try us for free</span>
                                </a>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="slice slice-lg bg-secondary">
            <div class="container">
                <div class="row row-grid ">
                    <div class="col-md-12 col-lg-12  ml-lg-auto ">
                        <div class="pr-md-4">
                            <h1 class="heading h1">real estate image processing</h1>
                            <p class="lead text-gray my-4">capture attention and captivate your audience with professionally edited real estate photos from
                                imageingine. We understand that your business demands a lot from you, which is why our business
                                is designed to be your go-to source for advanced real estate image processing. Our expertly trained team
                                of professional photo editors will quickly and seamlessly enhance the visual quality of your
                                images while removing this time-consuming task from your to-do list.</p>
                        </div>
                    </div>
                </div>
            </div>
            <a href="#gallery" class="tongue tongue-bottom scroll-me"><i class="fas fa-angle-down"></i></a>
        </section>
        <!-- Image + Content -->
        <section class="slice slice-xl bg-white" id="gallery">
            <div class="container">
                <div class="row row-grid justify-content-center">
                    <div class="col-md-12 col-lg-12  ml-lg-auto ">
                        <div class="pr-md-4">
                            <h3 class="heading h1">our edits</h3>
                            <p class="lead text-gray my-4">create lead generating, attention grabbing real estate images designed to compel sales quickly &amp; professionally.</p>
                        </div>
                    </div>
                </div>
                    <div class="code-example">
                        <div class="row">
                            <div class="col-12 ">
                                <h3>
                                    living room
                                </h3>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 my-4">
                                <a href="../assets/img/services/rei/real-estate-image-processing-1.{{ImageExtension}}" data-fancybox="group">
                                    <img src="../assets/img/services/rei/thumbs/real-estate-image-processing-1-thumb.{{ImageExtension}}" class="img-fluid rounded" alt="01"/>
                                </a>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 my-4">
                                <a href="../assets/img/services/rei/real-estate-image-processing-2.{{ImageExtension}}" data-fancybox="group">
                                    <img src="../assets/img/services/rei/thumbs/real-estate-image-processing-2-thumb.{{ImageExtension}}" class="img-fluid rounded" alt="01"/>
                                </a>
                            </div>
                            <div class="col-12 ">
                                <h3>
                                    kitchen
                                </h3>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 my-4">
                                <a href="../assets/img/services/rei/real-estate-image-processing-3.{{ImageExtension}}" data-fancybox="group">
                                    <img src="../assets/img/services/rei/thumbs/real-estate-image-processing-3-thumb.{{ImageExtension}}" class="img-fluid rounded" alt="01"/>
                                </a>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 my-4">
                                <a href="../assets/img/services/rei/real-estate-image-processing-4.{{ImageExtension}}" data-fancybox="group">
                                    <img src="../assets/img/services/rei/thumbs/real-estate-image-processing-4-thumb.{{ImageExtension}}" class="img-fluid rounded" alt="01"/>
                                </a>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 my-4">
                                <a href="../assets/img/services/rei/real-estate-image-processing-5.{{ImageExtension}}" data-fancybox="group">
                                    <img src="../assets/img/services/rei/thumbs/real-estate-image-processing-5-thumb.{{ImageExtension}}" class="img-fluid rounded" alt="01"/>
                                </a>
                            </div>
                            <div class="col-12 ">
                                <h3>
                                    bed room
                                </h3>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 my-4">
                                <a href="../assets/img/services/rei/real-estate-image-processing-6.{{ImageExtension}}" data-fancybox="group">
                                    <img src="../assets/img/services/rei/thumbs/real-estate-image-processing-6-thumb.{{ImageExtension}}" class="img-fluid rounded" alt="01"/>
                                </a>
                            </div>
                            <div class="col-12 ">
                                <h3>
                                    bath room
                                </h3>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 my-4">
                                <a href="../assets/img/services/rei/real-estate-image-processing-7.{{ImageExtension}}" data-fancybox="group">
                                    <img src="../assets/img/services/rei/thumbs/real-estate-image-processing-7-thumb.{{ImageExtension}}" class="img-fluid rounded" alt="01"/>
                                </a>
                            </div>
                            <div class="col-12 ">
                                <h3>
                                    day to twilight
                                </h3>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 my-4">
                                <a href="../assets/img/services/rei/real-estate-image-processing-8.{{ImageExtension}}" data-fancybox="group">
                                    <img src="../assets/img/services/rei/thumbs/real-estate-image-processing-8-thumb.{{ImageExtension}}" class="img-fluid rounded" alt="01"/>
                                </a>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 my-4">
                                <a href="../assets/img/services/rei/real-estate-image-processing-9.{{ImageExtension}}" data-fancybox="group">
                                    <img src="../assets/img/services/rei/thumbs/real-estate-image-processing-9-thumb.{{ImageExtension}}" class="img-fluid rounded" alt="01"/>
                                </a>
                            </div>
                            <div class="col-12 ">
                                <h3>
                                    exteriors + sky replacement
                                </h3>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 my-4">
                                <a href="../assets/img/services/rei/real-estate-image-processing-10.{{ImageExtension}}" data-fancybox="group">
                                    <img src="../assets/img/services/rei/thumbs/real-estate-image-processing-10-thumb.{{ImageExtension}}" class="img-fluid rounded" alt="01"/>
                                </a>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12 my-4">
                                <a href="../assets/img/services/rei/real-estate-image-processing-11.{{ImageExtension}}" data-fancybox="group">
                                    <img src="../assets/img/services/rei/thumbs/real-estate-image-processing-11-thumb.{{ImageExtension}}" class="img-fluid rounded" alt="01"/>
                                </a>
                            </div>
                        </div>
                    </div>
            </div>
        </section>
        <app-service-features></app-service-features>
    </div>
</main>
<app-footer></app-footer>