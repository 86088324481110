import { Component, OnInit } from '@angular/core';
import { Title, Meta }     from '@angular/platform-browser';

@Component({
  selector: 'app-photo-restoration',
  templateUrl: './photo-restoration.component.html',
  styleUrls: ['./photo-restoration.component.css']
})
export class PhotoRestorationComponent implements OnInit {

  constructor(private titleService: Title,private meta: Meta) { }

  ngOnInit() {
    // this.titleService.setTitle("Photo Restoration");
  }

}
