<app-header></app-header>
<main ondragstart="return false;" ondrop="return false;">
    <!-- Spotlight -->
    <div id="AMRbody">
        <section class="slice slice-lg bg-cover bg-size--cover overflow-hidden" style="background-position: center; background-image: url(/assets/img/backgrounds/automobile-retouching.jpg);">
            <span class="mask bg-gradient-primary opacity-9"></span>
            <div class="section-inner bg-gradient-primary">
                <span class="section-inner-bg"></span>
            </div>
            <div class="container py-sm">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                            <div class="pt-lg-lg pb-lg-md">
                        <h1 class="heading display-4 font-weight-300 text-white lh-110 mb-4">automobile retouching</h1>
                        <p class="lead text-white lh-180">impress everyone with the sleek and shiny drive. perfectly highlighting the automobile features, each image can be displayed in the setting of your choice.</p>
                        <div class="mt-5">
                            <a href="#gallery" class="btn btn-white btn-circle btn-sm btn-translate--hover btn-icon mr-sm-4 scroll-me" data-scroll-to-offset="0">
                                <span class="btn-inner--text">View gallery</span>
                                <span class="btn-inner--icon">
                                    <i class="fas fa-angle-right"></i>
                                </span>
                            </a>
                            <a [routerLink]="['/upload-now']" class="btn btn-outline-white btn-icon btn-circle btn-sm px-4">
                                    <span class="btn-inner--icon"><i class="fas fa-play"></i></span>
                                    <span class="btn-inner--text">try us for free</span>
                                </a>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="slice slice-lg bg-secondary">
            <div class="container">
                <div class="row row-grid ">
                    <div class="col-md-12 col-lg-12  ml-lg-auto ">
                        <div class="pr-md-4">
                            <h1 class="heading h1">automobile retouching</h1>
                            <p class="lead text-gray my-4">like any other industry, the automotive industry also weighs its advertising heavily on automobile photo and video shots that are used in digital and print media. the images should have a strong impact on the end customer to entice them to head to a showroom. we process automobile images to make them ideal for pushing sales. our automobile retouching services will result in images that resemble the actual vehicle, onto the 2-d layer, with the best features of each one highlighted appropriately. we will ensure that the vehicle’s original colour is reflected in the image, remove any clutter in the shot and change the background that best suits the client’s requirement.</p>
                        </div>
                    </div>
                </div>
            </div>
            <a href="#gallery" class="tongue tongue-bottom scroll-me"><i class="fas fa-angle-down"></i></a>
        </section>
        <!-- Image + Content -->
        <section class="slice slice-xl bg-white" id="gallery">
            <div class="container">
                <div class="row row-grid justify-content-center">
                    <div class="col-md-12 col-lg-12  ml-lg-auto ">
                        <div class="pr-md-4">
                            <h3 class="heading h1">our edits</h3>
                            <p class="lead text-gray my-4">impress everyone with the sleek and shiny drive. perfectly highlighting the automobile features, each image can be displayed in the setting of your choice.</p>
                        </div>
                    </div>
                </div>
                    <div class="code-example">
                        we will update soon...
                    </div>
            </div>
        </section>
        <app-service-features></app-service-features>
    </div>
</main>
<app-footer></app-footer>