import { Component, OnInit } from '@angular/core';
import { Title, Meta }     from '@angular/platform-browser';
import * as  Globals  from '../global';

@Component({
  selector: 'app-virtual-staging',
  templateUrl: './virtual-staging.component.html',
  styleUrls: ['./virtual-staging.component.css']
})
export class VirtualStagingComponent implements OnInit {
  ImageExtension: any;
  constructor(private titleService: Title,private meta: Meta) {
    this.ImageExtension = Globals.getBrowserExtension();
   }

   ngOnInit() {
    setTimeout(() => {
      $("#VSbody").bind('cut copy paste', function (e) { e.preventDefault(); });
      $("body").on("contextmenu",function(e){ return false; });
      $('#VSbody').on('drop', function() { return false; });
      $('body').bind('keydown', function(event) {
        if(event.ctrlKey && event.shiftKey && event.keyCode==73){ event.preventDefault(); }
        if(event.keyCode==123){ event.preventDefault(); }
        if (event.ctrlKey || event.metaKey) {
          switch (String.fromCharCode(event.which).toLowerCase()) {
          case 's':
            event.preventDefault();
            break;
          case 'f':
            event.preventDefault();
            break;
          case 'g':
            event.preventDefault();
            break;
          }
        }
      });
    }, 500);
  }

}
