import { WINDOW } from '@ng-toolkit/universal';
import { Component, OnInit , Inject} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  AboutActive:any = ""; 
  ServiceActive:any = "";
  VideoActive:any = "";
  ContactActive:any = "";

  constructor(@Inject(WINDOW) private window: Window, private router: Router) { 
    if(router.url == "/about"){
      this.AboutActive = "active";
      this.ServiceActive = "";
      this.VideoActive = "";
      this.ContactActive = "";
    }else if(router.url == "/contact"){
      this.AboutActive = "";
      this.ServiceActive = "";
      this.VideoActive = "";
      this.ContactActive = "active";
    }else if(router.url == "/video-editing"){
      this.AboutActive = "";
      this.ServiceActive = "";
      this.VideoActive = "active";
      this.ContactActive = "";
    }else if(router.url == "/home"){
      this.AboutActive = "";
      this.ServiceActive = "";
      this.VideoActive = "";
      this.ContactActive = "";
    }else{
      this.AboutActive = "";
      this.ServiceActive = "active";
      this.VideoActive = "";
      this.ContactActive = "";
    }
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) { return; }
      this.window.scrollTo(0, 0)
    });
  }

  gotoHome(){
    this.router.navigated = false;
    this.router.navigateByUrl("/",{ skipLocationChange: false });
  }
}
