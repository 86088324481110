<app-header></app-header>
<main>
    <section class="bg-dark bg-cover bg-size--cover homepage-banner" data-spotlight="fullscreen" style="background-position: center; background-image: url(/assets/img/backgrounds/realestate-image-editing-from-Imageingine.jpg);">
        <span class="mask bg-dark opacity-4"></span>
        <div class="spotlight py-lg pt-lg-xl">
            <div class="container d-flex align-items-center">
                <div class="col">
                    <div class="row cols-xs-space align-items-end text-center text-md-left justify-content-center">
                        <div class="col-lg-9">
                            <div class="text-center">
                                <h2 class="text-white my-4">
                                    <span class="display-4 font-weight-bold">the editors of the <br>visual world</span>
                                </h2>
                            <p class="lead text-white">a complete gamut of image editing services for the visual industry. our advance technologies combined with human elements take the processed images to the next level!</p>
                            <div class="mt-5">
                                <a [routerLink]="['/realestate-image-processing']" class="btn btn-white rounded-pill hover-scale-110 btn-icon mr-sm-4 scroll-me">
                                    <span class="btn-inner--text">read more</span>
                                    <span class="btn-inner--icon"><i class="fas fa-angle-right"></i></span>
                                </a>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a href="#sct_page_examples" class="tongue tongue-bottom scroll-me"><i class="fas fa-angle-down"></i></a>
    </section>
    <section id="sct_page_examples" class="slice slice-lg">
        <div class="container-fluid">
            <div class="mb-5 text-center">
                <h3 class="h2 mb-3">image editing services</h3>                
                <div class="fluid-paragraph text-center">
                    <p class="lead mb-0">come look at some of our image editing services. We assure, you will be gratified with our work quality. every single project of yours, no matter the size, will be delivered with the same quality and  effort from our side.</p>                </div>
            </div>
            <div class="row row-grid">
                
                <div class="col-lg-4">
                    <div class="card border-0">
                    <a [routerLink]="['/realestate-image-processing']" data-animate-hover="1">
                        <div class="animate-this">
                            <img class="rounded z-depth-3 img-fluid" src="../assets/img/services/real-estate-image-editing-thumb.{{ImageExtension}}" style="width: 100%;" alt="real-estate-image-editing-thumb">
                            <span class="mask bg-dark opacity-0"></span>
                            <span class="mask mask--hover "></span>
                            <div class="card-img-overlay d-flex">
                                <div class="col pt-2">
                                    <h4 class="h3 text-white mb-1" style="opacity: 0;">real estate image processing</h4>
                                </div>
                            </div>
                        </div>
                    </a>
                    <div class="pt-4">
                        <small class="text-uppercase">Oct 15, 2019</small>
                        <h5>real estate image processing</h5>
                        <p class="mt-3">an image is the starting point to selling. our processed real estate images will ensure client’s get a feel of house just from a picture. </p>
                    </div>
                    </div>
                </div>
                <div class="col-lg-4">
                        <div class="card border-0">
                        <a [routerLink]="['/beauty-portrait-retouching']" data-animate-hover="1">
                                <div class="animate-this">
                                    <img class="rounded z-depth-3 img-fluid" src="../assets/img/services/beauty-portrait-retouching-thumb.{{ImageExtension}}" style="width: 100%;" alt="beauty-portrait-retouching-thumb">
                                    <span class="mask bg-dark opacity-0"></span>
                                    <span class="mask mask--hover"></span>
                                    <div class="card-img-overlay d-flex">
                                        <div class="col pt-2">
                                            <h4 class="h3 text-white mb-1" style="opacity: 0;">beauty &amp; portrait retouching</h4>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <div class="pt-4">
                                <small class="text-uppercase">Oct 15, 2019</small>
                                <h5>beauty & portrait retouching</h5>
                                <p class="mt-3">get that perfect portfolio shot with us. our team works on standard editing projects & those that require advance editing with same zeal and dedication.  </p>
                            </div>
                            </div>
                    </div>
                <div class="col-lg-4">
                        <div class="card border-0">
                        <a [routerLink]="['/image-clipping-path']" data-animate-hover="1">
                                <div class="animate-this">
                                    <img class="rounded z-depth-3 img-fluid" src="../assets/img/services/e-commerce-image-processing-thumb.{{ImageExtension}}" style="width: 100%;" alt="e-commerce-image-processing-thumb">
                                    <span class="mask bg-dark opacity-0"></span>
                                    <span class="mask mask--hover "></span>
                                    <div class="card-img-overlay d-flex">
                                        <div class="col pt-2">
                                            <h4 class="h3 text-white mb-1" style="opacity: 0;">clipping path services</h4>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <div class="pt-4">
                                <small class="text-uppercase">Oct 15, 2019</small>
                                <h5>clipping path services</h5>
                                <p class="mt-3">we create the perfect cut for the perfect ad. our team’s passion in this field will make all the difference in your processed images.</p>
                            </div>
                            </div>
                </div>
            </div>
            <div id="call-to-action-call-to-action-7">
                  <div class="container">
                    <div class="row justify-content-center">
                      <div class="col-lg-8">
                        <div class="card bg-gradient-success rounded shadow-lg border-0 text-center position-relative zindex-100">
                          <div class="card-body p-5">
                            <h3 class="text-white font-weight-400">*free samples</h3>
                            <p class="text-white">don’t worry about making a choice without getting to know us firsthand. get a few samples processed with us for free before you make the choice to retain us.</p>
                            <div class="mt-4">
                              <a [routerLink]="['/image-editing-services']" class="btn btn-white btn-icon rounded-pill px-4">
                                <span class="btn-inner--text">explore all image editing services</span>
                                <span class="btn-inner--icon"><i class="fas fa-angle-right"></i></span>
                              </a>
                              <a [routerLink]="['/contact']" class="btn btn-link text-white">Write to us for custom requirement</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            <!--<div class="fluid-paragraph text-center mt-5">
                <p><strong class="text-primary">*free samples!</strong> don’t worry about making a choice without getting to know us firsthand. Get a few samples processed with us for free before you make the choice to retain us.</p>
            </div>
            <div class="mt-5 text-center">
                <a [routerLink]="['/image-editing-services']" class="btn btn-primary rounded-pill btn-icon mr-sm-4 scroll-me">
                    <span class="btn-inner--text">explore all image editing services</span>
                    <span class="btn-inner--icon"><i class="fas fa-angle-right"></i></span>
                </a></div>-->
        </div>
    </section>
    <section class="slice slice-xl bg-gradient-primary has-floating-items" id="sct_call_to_action">
        <a href="#sct_call_to_action" class="tongue tongue-up scroll-me"><i class="fas fa-angle-up"></i></a>
        <div class="container text-center">
            <div class="row">
                <div class="col-12">
                    <h1 class="text-white strong-600">complete workflow at your hand</h1>
                    <div class="row justify-content-center mt-4">
                        <div class="col-lg-8">
                            <p class="lead text-white">
                                you can handle the entire workflow by yourself. From uploading on our portal, checking your task details and current status, expected time for completion, to downloading the processed images, you can do it all by yourself without having to worry about the delivery or follow up calls to us.
                            </p>
                            <div class="btn-container mt-5">
                                <a [routerLink]="['/upload-now']" class="btn btn-white btn-circle rounded-pill px-4 mr-lg-4">upload now<span class="badge badge-pill badge-soft-warning badge-floating border-">Free Samples</span></a>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="container floating-items">
            <div class="icon-floating bg-white floating">
              <span></span>
              <img alt="Image placeholder" src="assets/img/icons/dusk/svg/add.svg" class="svg-inject" width="50px" height="50px">
            </div>
            <div class="icon-floating icon-lg bg-white floating">
              <span></span>
              <img alt="Image placeholder" src="assets/img/icons/dusk/svg/change-theme.svg" class="svg-inject" width="76px" height="76px">
            </div>
            <div class="icon-floating icon-sm bg-white floating">
              <span></span>
              <img alt="Image placeholder" src="assets/img/icons/dusk/svg/comments.svg" class="svg-inject" width="36px" height="36px">
            </div>
            <div class="icon-floating icon-lg bg-white floating">
              <span></span>
              <img alt="Image placeholder" src="assets/img/icons/dusk/svg/download.svg" class="svg-inject" width="76px" height="76px">
            </div>
            <div class="icon-floating bg-white floating">
              <span></span>
              <img alt="Image placeholder" src="assets/img/icons/dusk/svg/form.svg" class="svg-inject" width="46px" height="46px">
            </div>
            <div class="icon-floating icon-sm bg-white floating">
              <span></span>
              <img alt="Image placeholder" src="assets/img/icons/dusk/svg/ios-photos.svg" class="svg-inject" width="36px" height="36px">
            </div>
          </div>
    </section>
    <section class="slice slice-lg bg-secondary why-imageingine">
            <div class="container">
                <div class="row row-grid align-items-center">
                    <div class="col-lg-4">
                        <div class="pt-lg-md pb-lg-md text-center text-lg-left">
                            <h2 class="heading h3 mb-3">why imageingine?</h2>
                            <p class="lead lh-180">with laser sharp focus on quality &amp; value, we aim to deliver cost-effective solutions, with our clients’ interests as our goal.</p>
                            <a [routerLink]="['/contact']" class="btn btn-primary btn-circle mt-4">contact us</a>
                        </div>
                    </div>
                    <div class="col-lg-7 ml-lg-auto">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="card mb-4">
                                    <div class="card-body">
                                        <div class="icon text-primary mb-3">
                                            <img src="../assets/img/icons/dusk/svg/cloud.svg" class="img-fluid" alt="cloud" style="height: 48px;">
                                        </div>
                                        <h5 class="h5">cloud based</h5>
                                        <p>get everything done on the cloud, without leaving your place. Use our portal to upload your raw images and download the processed ones.</p>
                                    </div>
                                </div>
                                <div class="card mb-4">
                                    <div class="card-body">
                                        <div class="icon text-primary mb-3">
                                            <img src="../assets/img/icons/dusk/svg/speed.svg" class="img-fluid" alt="speed" style="height: 48px;">
                                        </div>
                                        <h5 class="h5">superfast delivery</h5>
                                        <p>we not only work fast, but we also ensure to maintain the high quality that meets your standards.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 pt-lg-md pt-4">
                                <div class="card mb-4">
                                    <div class="card-body">
                                        <div class="icon text-primary mb-3">
                                            <img src="../assets/img/icons/dusk/svg/good-quality.svg" class="img-fluid" alt="good-quality" style="height: 48px;">
                                        </div>
                                        <h5 class="h5">supreme quality</h5>
                                        <p>we not only work fast, but we also ensure to maintain the high quality that meets your standards.</p>
                                    </div>
                                </div>
                                <div class="card mb-4">
                                    <div class="card-body">
                                        <div class="icon text-primary mb-3">
                                            <img src="../assets/img/icons/dusk/svg/low-price.svg" class="img-fluid" alt="low-price" style="height: 48px;">
                                        </div>
                                        <h5 class="h5">cost-efficient</h5>
                                        <p>our uncompromised quality is accompanied with cost-saving rates. We are sure you will want to retain Imageingine as you trusted partners.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="slice slice-lg" data-delimiter-after="1" >
            <a href="#sct_features" class="tongue tongue-up tongue-secondary scroll-me"><i class="fas fa-angle-up"></i></a>
            <div class="container">
                <div class="row row-grid align-items-center justify-content-around">
                    <div class="col-lg-5 order-lg-2">
                        <img src="assets/img/svg/hello.svg" class="img-center img-fluid w-75">
                    </div>
                    <div class="col-lg-5 order-lg-1">
                        <div class="pr-md-4">
                            <div class="icon icon-shape shadow rounded-circle bg-yellow text-white mb-4">
                                <i class="fas fa-magic"></i>
                            </div>
                            <h1 class="h3">Human touch for the X factor</h1>
                            <p class="lead">It’s not just state-of-the-art software that works on your projects, but each image will be processed by us. This added human element to each image will give it that unparalleled special quality.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    <section class="slice slice-lg" >
        <div class="container">
            <div class="row row-grid align-items-center justify-content-around">
                <div class="col-lg-5">
                    <div class="bg-circles d-none">
                        <span></span>
                        <span></span>
                        <span></span>
                        <!-- <span></span> -->
                    </div>
                    <img src="assets/img/svg/static_assets.svg" class="img-center img-fluid w-75 mx-auto">
                </div>
                <div class="col-lg-5">
                    <div class="pr-md-4">
                        <div class="icon icon-shape shadow rounded-circle bg-orange text-white mb-4">
                            <i class="fab fa-html5"></i>
                        </div>
                        <h1 class="h3">Made with latest technology</h1>
                        <p class="lead">Your projects will be worked upon with the most advance software and technologies. This will not only generate smooth results, but will also do so within a small time frame.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
</main>
<app-footer></app-footer>