<app-header></app-header>
<main>
        <!-- Spotlight -->
        <section class="slice slice-xl section-rotate d-flex align-items-center  bg-secondary">
                <div class="section-inner bg-gradient-primary">
                    <span class="section-inner-bg"></span>
                </div>
                <div class="container py-sm">
                    <div class="row align-items-center">
                        <div class="col-lg-5">
                            <h1 class=" text-white mb-3">Photo Manipulation</h1>
                            <p class="lead text-white lh-180">You’ll never know what the photo looked like before. Our staff will work on each project personally to make a difference to the final result.</p>
                            <div class="mt-5">
                                <a href="#gallery" class="btn btn-white btn-circle btn-sm btn-translate--hover btn-icon mr-sm-4 scroll-me" data-scroll-to-offset="0">
                                    <span class="btn-inner--text">View gallery</span>
                                    <span class="btn-inner--icon"><i class="fas fa-angle-right"></i></span>
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-7 ml-lg-auto">
                            <img src="../assets/img/prv/macbook-pro-clay.png" class="img-fluid d-none d-lg-block">
                        </div>
                    </div>
                </div>
            </section>
        <section class="slice slice-xl bg-secondary" style="padding-top: 0">
            <div class="container">
                    <div class="row row-grid ">
                            <div class="col-md-12 col-lg-12  ml-lg-auto ">
                                <div class="pr-md-4 ">
                                    <h3 class="heading h3">Photo Manipulation</h3>
                                    <p class="lead text-gray my-4">At Imageingine, our domain expertise is in photo manipulation and enhancement. Photo manipulation not only needs the skill but also creativity to produce striking images. Our team has the required experience and creative mind to extract results that match our client’s requirements. No matter which industry our client is from, we will alter the images to bring their imagination to life. The spirit of the image will be such that it creates a lasting impression on the mind. Photo manipulation encompasses multiple services, including:</p>
                                    <ul>
                                      <li>Resizing various features, incorporation additional features or removing specific ones</li>
                                      <li>Adding watermark</li>
                                      <li>Creating cartoons, paintings, sketches and more, out of the photographs</li>
                                      <li>Enhancement of the photo and removal of unnecessary elements</li>
                                      <li>Incorporating or removing objects and people in the photographs</li>
                                    </ul>
                                  </div>
                            </div>
                        </div>
            </div>
        </section>
        <!-- Image + Content -->
        <app-service-features></app-service-features>
        <section class="slice slice-xl bg-secondary" id="gallery">
                <div class="container">
                        <div class="row row-grid justify-content-center">
                                <div class="col-md-12 col-lg-12  ml-lg-auto ">
                                    <div class="pr-md-4 text-center">
                                        <h3 class="heading h3">Our edits</h3>
                                        <p class="lead text-gray my-4">Create lead generating, attention grabbing real estate images designed to compel sales quickly &amp; professionally. </p>
                                    </div>
                                </div>
                            </div>
                    <div class="row row-grid">
                    <div class="code-example">
                            <div class="row">
                                <div class="col-4 my-4">
                                    <a href="../assets/img/prv/img-1-800x600.jpg" data-fancybox="group" data-caption="My caption">
                                        <img src="../assets/img/prv/img-1-800x600.jpg" class="img-fluid rounded" />
                                    </a>
                                </div>
                                <div class="col-4 my-4">
                                    <a href="../assets/img/prv/img-2-800x600.jpg" data-fancybox="group" data-caption="My caption">
                                        <img src="../assets/img/prv/img-2-800x600.jpg" class="img-fluid rounded" />
                                    </a>
                                </div>
                                <div class="col-4 my-4">
                                        <a href="../assets/img/prv/img-1-800x600.jpg" data-fancybox="group" data-caption="My caption">
                                            <img src="../assets/img/prv/img-1-800x600.jpg" class="img-fluid rounded" />
                                        </a>
                                </div>
                                <div class="col-4 my-4">
                                        <a href="../assets/img/prv/img-1-800x600.jpg" data-fancybox="group" data-caption="My caption">
                                            <img src="../assets/img/prv/img-1-800x600.jpg" class="img-fluid rounded" />
                                        </a>
                                    </div>
                                    <div class="col-4 my-4">
                                        <a href="../assets/img/prv/img-2-800x600.jpg" data-fancybox="group" data-caption="My caption">
                                            <img src="../assets/img/prv/img-2-800x600.jpg" class="img-fluid rounded" />
                                        </a>
                                    </div>
                                    <div class="col-4 my-4">
                                            <a href="../assets/img/prv/img-1-800x600.jpg" data-fancybox="group" data-caption="My caption">
                                                <img src="../assets/img/prv/img-1-800x600.jpg" class="img-fluid rounded" />
                                            </a>
                                    </div>
                                    <div class="col-4 my-4">
                                            <a href="../assets/img/prv/img-1-800x600.jpg" data-fancybox="group" data-caption="My caption">
                                                <img src="../assets/img/prv/img-1-800x600.jpg" class="img-fluid rounded" />
                                            </a>
                                        </div>
                                        <div class="col-4 my-4">
                                            <a href="../assets/img/prv/img-2-800x600.jpg" data-fancybox="group" data-caption="My caption">
                                                <img src="../assets/img/prv/img-2-800x600.jpg" class="img-fluid rounded" />
                                            </a>
                                        </div>
                                        <div class="col-4 my-4">
                                                <a href="../assets/img/prv/img-1-800x600.jpg" data-fancybox="group" data-caption="My caption">
                                                    <img src="../assets/img/prv/img-1-800x600.jpg" class="img-fluid rounded" />
                                                </a>
                                        </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </main>
    <app-footer></app-footer>