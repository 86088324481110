import { Component, OnInit } from '@angular/core';
import { Title, Meta }     from '@angular/platform-browser';
@Component({
  selector: 'app-photo-manipulation',
  templateUrl: './photo-manipulation.component.html',
  styleUrls: ['./photo-manipulation.component.css']
})
export class PhotoManipulationComponent implements OnInit {

  constructor(private titleService: Title,private meta: Meta) { }

  ngOnInit() {
    // this.titleService.setTitle("Photo Manipulation");
  }

}
