import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-photography-videography',
  templateUrl: './photography-videography.component.html',
  styleUrls: ['./photography-videography.component.css']
})
export class PhotographyVideographyComponent implements OnInit {

  ImagesBlock: boolean;
  VideosBlock: boolean;

  ImageActiveClass: any; VideoActiveClass: any;
  constructor() {
    this.ImagesBlock = true;
    this.VideosBlock = false;
    this.ImageActiveClass = "bg-primary";
    this.VideoActiveClass = "bg-dark";
  }

  ngOnInit() {
    
  }
  enableBlocks(val:any){
    if(val == "Images"){
      this.ImagesBlock = true;
      this.VideosBlock = false;
      this.ImageActiveClass = "bg-primary";
      this.VideoActiveClass = "bg-dark";
    }else if(val == "Videos"){
      this.ImagesBlock = false;
      this.VideosBlock = true;
      this.ImageActiveClass = "bg-dark";
      this.VideoActiveClass = "bg-primary";
    }
  }
}
