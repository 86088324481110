import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClientModule, HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import * as Globals from '../global';
const URL = 'http://localhost:3000/DownloadFiles';
@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
export class DownloadComponent implements OnInit {
  TokenValue: any;
  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router) { 
    this.route.params.subscribe(params => {this.TokenValue = params['t']});
    this.getOrderFilesList();
  }

  ngOnInit() {

  }
  FilesList = []; FilesLists = []; FilesListValue: any;CustomerEmail: any; CustomerName: any; ProjectName: any; Instructions: any;
  getOrderFilesList(){
    this.http.post(Globals.PageUrls.getorderFiles,JSON.stringify({Token : this.TokenValue})).subscribe(data => {
      this.FilesListValue = data;
      this.FilesListValue.forEach(element => {
        this.FilesLists.push(element);
      });
      this.FilesList = this.FilesLists;
      this.Instructions = data[0]['FileNotes'];
      this.ProjectName = data[0]['ProjectName'];
      this.CustomerEmail = data[0]['CustomerEmail'];
      this.CustomerName = data[0]['CustomerName'];
    });
  }
  downloadAllFiles(){
    this.http.post(URL,{Token : this.TokenValue},{responseType: 'arraybuffer',headers:{"Content-Type": "application/json"}}).subscribe(resp => {
      console.log(resp);
      this.downLoadFile(resp, "application/zip");
    })
  }

  downLoadFile(data: any, type: string) {
    // var blob = new Blob([data], { type: type});
    // var url = window.URL.createObjectURL(blob);
    // var pwa = window.open(url);
    // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
    //     alert( 'Please disable your Pop-up blocker and try again.');
    // }
    let filename = "files.zip"
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob([data], {type: type}));
    if (filename)
        downloadLink.setAttribute('download', filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }
}
