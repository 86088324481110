<header class="header-transparent">
    <nav class="navbar navbar-main navbar-expand-lg navbar-sticky navbar-transparent navbar-dark bg-dark" id="navbar-main">
        <div class="container">
            <a class="navbar-brand mr-lg-5" (click)="gotoHome();" style="cursor: pointer;">
                <img alt="Image placeholder" src="./assets/img/brand/imageingine-logo.svg" style="height: 45px;">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar_main_collapse" aria-controls="navbar_main" aria-expanded="false" aria-label="Toggle navigation">
                <span class="fas fa-align-right"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbar_main_collapse">
                <ul class="navbar-nav align-items-lg-center">
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/about']">about</a>
                    </li>
                    <li class="nav-item dropdown dropdown-animate" data-toggle="hover">
                        <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Image Editing</a>
                        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-arrow p-0">
                          <ul class="list-group list-group-flush">
                            <li>
                                <a [routerLink]="['/realestate-image-processing']" class="list-group-item list-group-item-action" role="button">
                                  <div class="media d-flex align-items-center">
                                    <figure style="width: 50px;">
                                      <img alt="Image placeholder" src="./assets/img/icons/dusk/svg/home.svg" class="svg-inject img-fluid" style="height: 60px;">
                                    </figure>
                                    <div class="media-body ml-3">
                                      <h6 class="mb-1">real estate image processing</h6>
                                      <p class="mb-0">a great point to start from</p>
                                    </div>
                                  </div>
                                </a>
                              </li>
                              <li>
                                <a [routerLink]="['/virtual-staging']" class="list-group-item list-group-item-action" role="button">
                                  <div class="media d-flex align-items-center">
                                    <figure style="width: 50px;">
                                      <img alt="Image placeholder" src="./assets/img/icons/dusk/png/sofa.png" class="svg-inject img-fluid" style="height: 60px;">
                                    </figure>
                                    <div class="media-body ml-3">
                                      <h6 class="mb-1">virtual staging</h6>
                                      <p class="mb-0">digitally furnish vacant photographs</p>
                                    </div>
                                  </div>
                                </a>
                              </li>
                              <li>
                                <a [routerLink]="['/beauty-portrait-retouching']" class="list-group-item list-group-item-action" role="button">
                                  <div class="media d-flex align-items-center">
                                    <figure style="width: 50px;">
                                      <img alt="Image placeholder" src="./assets/img/icons/dusk/svg/portrait.svg" class="svg-inject img-fluid" style="height: 60px;">
                                    </figure>
                                    <div class="media-body ml-3">
                                      <h6 class="mb-1">beauty &amp; portrait retouching</h6>
                                      <p class="mb-0">get that perfect portfolio shot</p>
                                    </div>
                                  </div>
                                </a>
                              </li>
                              <li>
                                <a [routerLink]="['/ecommerce-image-processing']" class="list-group-item list-group-item-action" role="button">
                                  <div class="media d-flex align-items-center">
                                    <figure style="width: 50px;">
                                      <img alt="Image placeholder" src="./assets/img/icons/dusk/svg/new-product.svg" class="svg-inject img-fluid" style="height: 60px;">
                                    </figure>
                                    <div class="media-body ml-3">
                                      <h6 class="mb-1">ecommerce image processing</h6>
                                      <p class="mb-0">so tempting that they will want to buy immediately</p>
                                    </div>
                                  </div>
                                </a>
                              </li>
                              <li>
                                <a [routerLink]="['/image-clipping-path']" class="list-group-item list-group-item-action" role="button">
                                  <div class="media d-flex align-items-center">
                                    <figure style="width: 50px;">
                                      <img alt="Image placeholder" src="./assets/img/icons/dusk/svg/cut.svg" class="svg-inject img-fluid" style="height: 60px;">
                                    </figure>
                                    <div class="media-body ml-3">
                                      <h6 class="mb-1">image clipping path</h6>
                                      <p class="mb-0">the perfect cut for the perfect ad</p>
                                    </div>
                                  </div>
                                </a>
                              </li>
                              <li>
                                <a [routerLink]="['/image-editing-services']" class="list-group-item list-group-item-action" role="button">
                                  <div class="media d-flex align-items-center">
                                    <figure style="width: 50px;">
                                      <img alt="Image placeholder" src="./assets/img/icons/dusk/svg/details-pane.svg" class="svg-inject img-fluid" style="height: 60px;">
                                    </figure>
                                    <div class="media-body ml-3">
                                      <h6 class="mb-1">more services...</h6>
                                      <p class="mb-0">build your reputation with us</p>
                                    </div>
                                  </div>
                                </a>
                              </li>
                          </ul>
                          <div class="delimiter-top py-3 px-4">
                            <span class="badge badge-soft-success">Our pricing starts from as low as USD 0.40</span>
                            <p class="mt-2 mb-0">
                              build your reputation with us. we serve the whole gamut of photo and video editing, with high-quality and client satisfaction being our strong suit.
                            </p>
                          </div>
                        </div>
                      </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/video-editing']"  role="button">video editing</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/contact']" role="button">contact</a>
                    </li>
                    
                </ul>
                <ul class="navbar-nav align-items-lg-center ml-lg-auto">
                    <li class="nav-item mr-0">
                        <a [routerLink]="['/upload-now']" class="nav-link d-lg-none">Upload now</a>
                        <a [routerLink]="['/upload-now']" id="TryFreeButton" class="btn btn-sm btn-white rounded-pill btn-icon d-none d-lg-inline-flex">
                            <span class="btn-inner--icon"><i class="fas fa-tags"></i></span>
                            <span class="btn-inner--text">try for free</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>