import { Component, OnInit } from '@angular/core';
import { Title, Meta }     from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as  Globals  from '../global';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  ImageExtension: any;
  constructor(private titleService: Title,private meta: Meta, private router: Router) {
    this.ImageExtension = Globals.getBrowserExtension();
  }

  ngOnInit() { }

}