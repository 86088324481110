import { Component, OnInit,ViewChild } from '@angular/core';
import { RecaptchaModule } from 'ng-recaptcha';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import 'rxjs/add/observable/interval';
import { Title, Meta }     from '@angular/platform-browser';
import * as Globals from '../global';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit {
  recaptchaStr = '';
  Name: any; Email: any; Message: any; Phone: any;
  ContactDetailsForm: FormGroup;
  ErrorMessage: any;
  SucccessMessage: any;
  CaptchaError: any;
  CurrentTime: any;
  private corsHeaders: HttpHeaders;
  constructor(public formBuilder : FormBuilder, public http : HttpClient, private titleService: Title,private meta: Meta, private router: Router) {
    this.ContactDetailsForm = formBuilder.group({
      'Name': [null, Validators.required],
      'Email': [null, Validators.compose([Validators.required, Validators.email])],
      'Message': [null,null],
      'Phone': [null,null]
    });
    this.corsHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': 'https://imageingine.com/'
    });
  }

  ngOnInit() {
    
  }

  SubmitButton: boolean = true; SubmittingButton:boolean = false; AlertMessage:boolean = false;
  ManageContactForm($ev, value: any, captchaRef: any): void {
    // captchaRef.execute();
    $ev.preventDefault();    
    for (let c in this.ContactDetailsForm.controls) {this.ContactDetailsForm.controls[c].markAsTouched();}
    if (this.ContactDetailsForm.valid && this.recaptchaStr) {
      this.SubmitButton = false; this.SubmittingButton= true; this.AlertMessage = true;
      this.http.post(Globals.CheckURL.ContactURL,JSON.stringify(value),{headers: this.corsHeaders}).subscribe(data => {
        if(data['status'] == "error"){
          this.ErrorMessage = data['message'];
          setTimeout(()=>{ this.ErrorMessage = ""; },5000);
        }else if(data['status'] == "success"){
          this.SucccessMessage = data['message'];
          setTimeout(()=>{ this.SucccessMessage = ""; },5000);
        }
        captchaRef.reset();
        this.SubmitButton = true; this.SubmittingButton= false;
        this.ContactDetailsForm.reset();
      });
      this.CaptchaError = "";
      
    }else{
      this.CaptchaError = "re-Captcha is not validated please try again";
    }
    
  }
  public resolved(captchaResponse: string): void {
    this.recaptchaStr = captchaResponse;
    this.CaptchaError = "";
    if (this.recaptchaStr) {}

    
  }
}
