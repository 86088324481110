<app-header></app-header>
<main>
        <section class="slice bg-gradient-primary">
            <div class="container pt-lg-md">
                <div class="row">
                    <div class="col-lg-5 py-5">
                        <h1 class="text-white">Have a project in mind?</h1>
                        <p class="lead text-white mt-4">with every new project, we aspire to top our previous ones. your goal is ours.</p>
                         <a href="#sct_contact_form" class="btn rounded-pill btn-secondary btn-circle btn-icon btn-translate--hover mt-4 scroll-me">
                                <span class="btn-inner--icon"><i class="fas fa-pencil-alt"></i></span>
                                <span class="btn-inner--text">Write a message</span>
                            </a>                   </div> 
                </div>
            </div>
        </section>
        <section class="slice slice-lg bg-secondary" data-delimiter-before="1" id="sct_contact_form">
            <div class="container">
                <div class="row row-grid align-items-center">
                    <div class="col-lg-6">
                        <h3>Contact us</h3>
                        <p class="lead">come to us with any query that you have and we will be glad to help you out</p>
                        <!-- <form [formGroup]="ContactDetailsForm" role="form" name="ContactDetailsForm" novalidate="" (submit)="ManageContactForm($event, ContactDetailsForm.value, captchaRef);">
                                <div class="form-group">
                                    <label class="sr-only">Your name</label>
                                    <input class="form-control form-control" formControlName="Name" [(ngModel)]="Name" name="Name" placeholder="your name" type="text">
                                    <span class="text-danger" *ngIf="ContactDetailsForm.controls['Name'].hasError('required') && (ContactDetailsForm.controls['Name'].dirty || ContactDetailsForm.controls['Name'].touched)">This field is required</span>
                                </div>
                                <div class="form-group">
                                    <label class="sr-only">Email address</label>
                                    <input class="form-control form-control" formControlName="Email" [(ngModel)]="Email" name="Email" placeholder="email address" type="text" (change)="captchaRef.execute()">
                                    <span class="text-danger" *ngIf="ContactDetailsForm.controls['Email'].hasError('email') && (ContactDetailsForm.controls['Email'].dirty || ContactDetailsForm.controls['Email'].touched)">This field must be a valid email address</span>
                                </div>
                                <div class="form-group">
                                    <label class="sr-only">Phone number</label>
                                    <input class="form-control form-control" formControlName="Phone" [(ngModel)]="Phone" name="Phone" placeholder="phone number" type="text">
                                </div>
                                <div class="form-group">
                                    <label class="sr-only">Type your message</label>
                                    <textarea class="form-control form-control textarea-autosize" formControlName="Message" [(ngModel)]="Message" name="Message" rows="2" placeholder="type your message"></textarea>
                                </div>
                                <div class="clearfix"></div>
                                <div class="form-group">
                                    <re-captcha #captchaRef="reCaptcha" size="invisible"  (resolved)="resolved($event)"></re-captcha>
                                    <span class="text-red">{{CaptchaError}}</span>
                                </div>
                                <div class="clearfix"></div>
                                <div class="form-group" *ngIf="AlertMessage">
                                    <div class="alert alert-success" *ngIf="SucccessMessage"><strong>Success!</strong> {{SucccessMessage}}</div>
                                    <div class="alert alert-danger" *ngIf="ErrorMessage"><strong>Error!</strong> {{ ErrorMessage }}</div>
                                </div>
                                <div class="clearfix"></div>
                                <button *ngIf="SubmitButton" type="submit" class="btn btn-dark btn-circle" [disabled]="!ContactDetailsForm.valid">Send message</button>
                                <button *ngIf="SubmittingButton" class="btn btn-dark btn-circle" [disabled]=true>sending please wait...</button>
                            </form> -->
                            <h3>Bangalore, India</h3>
                  <p class="lead my-4">E: <a href="mailto:sales@imageingine.com">sales@imageingine.com </a> for sales
                    <br>E: <a href="mailto:support@imageingine.com">support@imageingine.com</a> for support
                  <br>T: <a href="tel:9901797672">+91 9901797672</a></p>
                        <p>no matter your project, from editing to shoots, we do it all while sticking to the time limit and maintaining the supreme quality. see the visual wonders on your screen, from anywhere.</p>
                    </div>
                    <div class="col-lg-5 ml-lg-auto">
                       <!-- <h3>Bangalore, India</h3>
                  <p class="lead my-4">E: <a href="mailto:sales@imageingine.com">sales@imageingine.com </a> for sales
                    <br>E: <a href="mailto:support@imageingine.com">support@imageingine.com</a> for support
                  <br>T: <a href="tel:9901797672">+91 9901797672</a></p>
                        <p>no matter your project, from editing to shoots, we do it all while sticking to the time limit and maintaining the supreme quality. see the visual wonders on your screen, from anywhere.</p> -->
                    </div>
                </div>
            </div>
        </section>
        <!-- Call to action -->
    </main>
    <app-footer></app-footer>
