import { HomeComponent } from './home/home.component';
import { RealestateImageProcessingComponent } from './realestate-image-processing/realestate-image-processing.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { BeautyPortraitRetouchingComponent } from "./beauty-portrait-retouching/beauty-portrait-retouching.component";
import { EcommerceImageProcessingComponent } from './ecommerce-image-processing/ecommerce-image-processing.component';
import { ImageClippingPathComponent } from "./image-clipping-path/image-clipping-path.component";
import { VideoEditingComponent } from "./video-editing/video-editing.component";
import { ServicesComponent } from "./services/services.component";
import { ImageEnhancementComponent } from "./image-enhancement/image-enhancement.component";
import { PhotoManipulationComponent } from "./photo-manipulation/photo-manipulation.component";
import { PhotoRestorationComponent } from "./photo-restoration/photo-restoration.component";
import { AutomobileRetouchingComponent } from "./automobile-retouching/automobile-retouching.component";
import { PhotographyVideographyComponent } from "./photography-videography/photography-videography.component";
import { ComingSoonComponent } from "./coming-soon/coming-soon.component";
import { UploadNowComponent } from "./upload-now/upload-now.component";
import { VirtualStagingComponent } from "./virtual-staging/virtual-staging.component";
import { FitnessritzPhotoshootComponent } from  "./fitnessritz-photoshoot/fitnessritz-photoshoot.component";
import { DownloadComponent } from "./download/download.component";

export const routes = [
    { path: '', component: HomeComponent,
    children: [
        { path: '', redirectTo: 'home', pathMatch: 'full', data: {title: 'welcome to imageingine | image editing services | Video editing services', metaDescription : "a complete gamut of image editing services for the visual industry."}}
    ], data: {title: 'welcome to imageingine | image editing services | Video editing services', metaDescription : "a complete gamut of image editing services for the visual industry."}},
    
    { path: 'home', component: HomeComponent, data: {title: 'welcome to imageingine | image editing services | Video editing services', metaDescription : "a complete gamut of image editing services for the visual industry."}},
    { path: 'realestate-image-processing', component: RealestateImageProcessingComponent, 
        data: {title: 'real estate image processing by imageingine', metaDescription : "We understand that your business demands a lot from you, which is why our business is designed to be your go-to source for advanced real estate image processing."} 
    },
    { path: 'beauty-portrait-retouching', component: BeautyPortraitRetouchingComponent, 
        data: {title: 'beauty and portrait retouching by imageingine', metaDescription : "beauty portrait retouching"}  
    },
    { path: 'ecommerce-image-processing', component: EcommerceImageProcessingComponent, 
        data: {title: 'ecommerce image processing by imageingine', metaDescription : "ecommerce image processing"}  
    },
    { path: 'image-clipping-path', component: ImageClippingPathComponent, 
        data: {title: 'image clipping path by imageingine', metaDescription : "image clipping path"}  
    },
    { path: 'video-editing', component: VideoEditingComponent, 
        data: {title: 'video editing by imageingine', metaDescription : "video editing"}  
    },
    { path: 'contact', component: ContactComponent, 
        data: {title: 'contact imageingine', metaDescription : "contact us with any query that you have and we will be glad to help you out."}  
    },
    { path: 'about', component: AboutComponent, 
        data: {title: 'about imageingine', metaDescription : "we indulge in the entire gamut of photo and video editing, aiming to optimize the workflow to minimize time while maintaining the high quality."} 
    },
    { path: 'image-editing-services', component: ServicesComponent, 
        data: {title: 'image editing services by imageingine', metaDescription : "image editing services"}  
    },
    { path: 'image-enhancement', component: ImageEnhancementComponent, 
        data: {title: 'image enhancement by imageingine', metaDescription : "image enhancement"}  
    },
    { path: 'photo-manipulation', component: PhotoManipulationComponent, 
        data: {title: 'photo manipulation by imageingine', metaDescription : "photo manipulation"}  
    },
    { path: 'photo-restoration', component: PhotoRestorationComponent, 
        data: {title: 'photo restoration by imageingine', metaDescription : "photo restoration"}  
    },
    { path: 'automobile-retouching', component: AutomobileRetouchingComponent, 
        data: {title: 'automobile retouching by imageingine', metaDescription : "automobile retouching"}  
    },
    { path: 'photography-videography', component: PhotographyVideographyComponent, 
        data: {title: 'photography and videography by imageingine', metaDescription : "photography videography"}  
    },
    { path: 'coming-soon', component: ComingSoonComponent, 
        data: {title: 'coming soon | imageingine', metaDescription : "coming soon"}  
    },
    { path: 'upload-now', component: UploadNowComponent, 
        data: {title: 'upload now to imageingine', metaDescription : "upload now"}  
    },
    { path: 'virtual-staging', component: VirtualStagingComponent, 
        data: {title: 'virtual staging services by imageingine', metaDescription : "virtual staging is the easiest way to make your properties stand out from the competition and help buyers fall in love with their next home."}  
    },
    { path: 'download', component: DownloadComponent, 
        data: {title: 'download services by imageingine', metaDescription : "download"}  
    },
    { path: '**', redirectTo: 'home', data: {title: 'home', metaDescription : "home page"}  }
];
