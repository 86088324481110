import { Component, OnInit } from '@angular/core';
import { Title, Meta }     from '@angular/platform-browser';
import * as  Globals  from '../global';
@Component({
  selector: 'app-image-clipping-path',
  templateUrl: './image-clipping-path.component.html',
  styleUrls: ['./image-clipping-path.component.css']
})
export class ImageClippingPathComponent implements OnInit {
  ImageExtension: any;
  constructor(private titleService: Title,private meta: Meta) { 
    this.ImageExtension = Globals.getBrowserExtension();
  }

  ngOnInit() {
    // this.titleService.setTitle("Clipping Path Services");
  }

}
