import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Title, Meta } from '@angular/platform-browser';
import * as Globals from '../global';
@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.css']
})
export class ComingSoonComponent implements OnInit {
  LaunchId: any;
  constructor(private router: Router,public http : HttpClient, private route: ActivatedRoute,private titleService: Title) { 
    // this.titleService.setTitle("Coming Soon");
  }

  ngOnInit() {
  }

}
