<app-header></app-header>
<main>
        <!-- Spotlight -->
        <section class="slice bg-cover bg-size--cover" data-spotlight="" >
            <span class="mask bg-primary"></span>
            <div class="spotlight-holder">
                <div class="container d-flex align-items-center px-0 pt-lg-md">
                    <div class="col">
                        <div class="row row-grid">
                            <div class="col-lg-8">
                                <div class="text-lg-left">
                                    <h2 class="heading display-4 text-white mt-6">About</h2>
                                    <p class="lead lh-180 text-white">We indulge in the entire gamut of photo and video editing, aiming to optimize the workflow to minimize time while maintaining the high quality.</p>
                                    <div class="mt-5 mb-5">
                                        <a [routerLink]="['/contact']" class="btn btn-white rounded-pill px-5 mr-lg-4 hover-scale-110 box-shadow-2">Contact us</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="slice slice-lg delimiter-bottom bg-secondary">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <h6 class="text-uppercase text-muted ls-2 mb-3">our company</h6>
                            <h2 class="lh-150">No matter your project, from editing to shoots, we do it all while sticking to the time limit and maintaining the supreme quality. See the visual wonders on your screen, from anywhere.</h2>
                            <p class="lead">We live in a visual world and us people absorb and respond to visual information best of all. Keeping that in mind and the needs of advertising in this ever-advancing age, we at Imageingine offer Photo and Video Editing services, to impart information to the human minds, the best way possible. With the sole purpose of contributing towards advertising and marketing for businesses, our team strives to deliver results that have an extensive reach to the end customers.</p>
                            <p class="lead">Be it for a product/project launch, a new business venture, a rebranding effort or simply just indulging in visuals for multiple products, the team at Imageingine will diligently work on each, without differentiating between them. Based out of Bangalore, India, and with a global outreach, Imageingine is dedicated to delivering a spectrum of services in the visual gamut.</p>
                        </div>
                    </div>
                </div>
            </section>
        <section class="slice">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <blockquote class="blockquote blockquote-card bg-secondary py-5 px-5 rounded-right">
                            <h3 class="h2 font-weight-700">Wise words</h3>
                            <p class="lead">Our clients’ goals are our goals. Maintaining consistency in Quality and Value, Imageingine will be the preferred choice for any and all visual-based projects that assist in client’s growth and sustenance. We are committed to delivering cost-effective, high-quality solutions to your visual requirements, within the requested time. </p>
                            <footer class="blockquote-footer"><cite class="font-weight-600" title="Source Title">pavan pulivarthi</cite> co founder, coo 
                            </footer>
                        </blockquote>
                    </div>
                </div>
            </div>
        </section>
        
        <!-- Call to action -->
        
    </main>
    <app-footer></app-footer>