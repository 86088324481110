import { Component, OnInit } from '@angular/core';
import { Title, Meta }     from '@angular/platform-browser';

@Component({
  selector: 'app-video-editing',
  templateUrl: './video-editing.component.html',
  styleUrls: ['./video-editing.component.css']
})
export class VideoEditingComponent implements OnInit {

  constructor(private titleService: Title,private meta: Meta) { }

  ngOnInit() {
    // this.titleService.setTitle("Video Editing Services");
  }

}
