<section class="slice slice-lg bg-secondary" id="service-features">
    <a href="#service-features" class="tongue tongue-top scroll-me"><i class="fas fa-angle-up"></i></a>
<div class="container">
<div class="row row-grid">
  <div class="col-lg-4">
      <div>
          <div class="icon text-primary mb-3">
              <img src="../assets/img/icons/dusk/svg/trial-version.svg" class="img-fluid">
          </div>
          <h5 class="h5">free samples</h5>
          <p>we know you can take your business anywhere, but we also know that the team at ImageIngine is the best in the field. Our free sample ensures you’re satisfied with the work we do before making a commitment.</p>
      </div>
    </div>
    <div class="col-lg-4">
        <div>
            <div class="icon text-primary mb-3">
                <img src="../assets/img/icons/dusk/svg/speed.svg" class="img-fluid">
            </div>
            <h5 class="h5">fast turnarounds</h5>
            <p>our professional real estate image post-processing services are completed in 24-48 hours. In a hurry? We also offer rush services that deliver completed images in just 8-12 hours.</p>
        </div>
    </div>
    <div class="col-lg-4">
        <div>
            <div class="icon text-primary mb-3">
                <img src="../assets/img/icons/dusk/svg/privacy.svg" class="img-fluid">
            </div>
            <h5 class="h5">commitment to privacy</h5>
            <p>your digital assets are the backbone of your business. We secure all images behind a firewall on in-house servers and our team adheres to a strict office privacy policy to keep your data save.</p>
        </div>
    </div>
  </div>
</div>
</section>