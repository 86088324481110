<app-header></app-header>
<style>
    tbody {
    display:block;
    max-height:195px;
    overflow:auto;
}
thead, tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;
}
label {
    margin-bottom: 0;
}
.progress {
    margin-top: 1rem;
}
.table tbody tr td .remove-file{
    display: none;
}
.table tbody tr td:hover .remove-file{
    display: block;
    margin-top: -7px;
    float: right;
}
.upload-success{
    float: right;
    font-size: 22px;
    margin-top: -11px;
}
.upload-error{
    float: right;
    margin-top: -11px;
    font-size: 22px;
}
.upload-progress{
    float: right;
    margin-top: -11px;
    font-size: 22px;
}
</style>
<main>
    <!-- Spotlight -->
    <section class="slice slice-xl py-9 bg-dark">
        <div class="container">
            <div class="row row-grid">
                
                <div class="col-lg-5" style="display:none">
                    <div class="card shadow">
                        <div class="card-header py-4">
                            <div class="d-flex align-items-center">
                                <div class="avatar-content">
                                    <h5 class="h6 mb-0">upload now</h5>
                                    <!-- <small class="d-block text-muted">to optimize your workflow create account</small> -->
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <form role="form" [formGroup]="UploadFileForm" class="form-validate" role="form" name="UploadFileForm" novalidate="" (submit)="manageUploadFileForm($event, UploadFileForm.value);">
                                <div class="form-group">
                                    <!-- <label class="form-control-label" data-toggle="tooltip" data-placement="top" title="Tooltip on top">Your Email<sup>*</sup></label> -->
                                    <input type="text" name="UserEmail" [(ngModel)]="UserEmail" formControlName="UserEmail" class="form-control form-control-sm form-control-emphasized" placeholder="your email">
                                    <span class="text-danger" *ngIf="UploadFileForm.controls['UserEmail'].hasError('required') && (UploadFileForm.controls['UserEmail'].dirty || UploadFileForm.controls['UserEmail'].touched)">This field is required</span>
                                    <span class="text-danger" *ngIf="UploadFileForm.controls['UserEmail'].hasError('email') && (UploadFileForm.controls['UserEmail'].dirty || UploadFileForm.controls['UserEmail'].touched)">This field must be a valid email address</span>
                                </div>
                                <div class="form-group">
                                    <label class="form-control-label">Name</label>
                                    <input type="text" name="UserName" [(ngModel)]="UserName" formControlName="UserName" class="form-control form-control-sm form-control-emphasized" placeholder="name">
                                </div>
                                <div class="form-group">
                                    <label class="form-control-label">Order Title<sup>*</sup></label>
                                    <input type="text" name="OrderTitle" [(ngModel)]="OrderTitle" formControlName="OrderTitle" class="form-control form-control-sm form-control-emphasized" placeholder="order title">
                                    <span class="text-danger" *ngIf="UploadFileForm.controls['OrderTitle'].hasError('required') && (UploadFileForm.controls['OrderTitle'].dirty || UploadFileForm.controls['OrderTitle'].touched)">This field is required</span>
                                </div>
                                <div class="form-group">
                                    <label class="form-control-label">Instructions</label>
                                    <textarea name="Instructions" [(ngModel)]="Instructions" formControlName="Instructions" class="form-control form-control-sm form-control-emphasized textarea-autosize"
                                        placeholder="type your instructions" rows="1"></textarea>
                                </div>
                                <div class="form-group" *ngIf="Trensferring">
                                    <label class="form-control-label">Upload Files<sup>*</sup></label>
                                    <input id="file" name="UploadFile" [(ngModel)]="UploadFile" formControlName="UploadFile" [accept]="allowedMimeType.toString()" type="file" ng2FileSelect [uploader]="uploader" multiple class="custom-input-file" />
                                    <label for="file"> <i class="fa fa-upload file-upload"></i><span>choose files…</span></label>
                                    <span class="text-danger" *ngIf="UploadFileForm.controls['UploadFile'].hasError('required') && (UploadFileForm.controls['UploadFile'].dirty || UploadFileForm.controls['UploadFile'].touched)">This field is required</span>
                                </div>
                                <div class="form-group" *ngIf="uploader?.queue?.length">
                                    <span class="badge badge-md badge-pill badge-info mr-2" *ngIf="uploader?.queue?.length">Queue
                                        length: {{ uploader?.queue?.length }}</span>
                                    <div class="table-responsive">
                                        <table class="table table-sm table-flush align-items-center align-items-center">
                                            <tbody>
                                                <tr *ngFor="let item of uploader.queue">
                                                    <td style="padding-left:0; line-height: 16px;">
                                                        <span class="font-weight-bold text-xs" style="color: #3e3d3d;">{{ item?.file?.name }}</span>
                                                        <div class="clearfix"></div>
                                                        <span class="text-xs">{{ item?.file?.size/1024/1024 | number:'.2' }} MB . {{ item?.file?.type }}</span>
                                                        <span class="text-xs" *ngIf="item.isSuccess"><i class="fas fa-check-circle text-success upload-success" data-toggle="tooltip" data-placement="top" title="Tooltip on top"></i></span>
                                                        <span class="text-xs" *ngIf="item.isUploading"><i class="fas fa-circle-notch fa-spin upload-progress" data-toggle="tooltip" data-placement="top" title="Tooltip on top"></i></span>
                                                        <span class="text-xs" *ngIf="item.isError"><i class="fas fa-times-circle text-danger upload-error" data-toggle="tooltip" data-placement="top" title="Tooltip on top"><a href="javascript:;" (click)="item.upload()"> retry</a></i></span>
                                                        <a (click)="item.remove()" class="remove-file" *ngIf="!item.isReady && !item.isSuccess && !item.isError">
                                                            <span class="btn-inner--icon"><i class="fas fa-times"></i></span>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="progress" *ngIf="uploader.progress!=0">
                                        <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"
                                            aria-valuenow="uploader.progress" aria-valuemin="0" aria-valuemax="100">{{uploader.progress}}%</div>
                                    </div>
                                </div>
                                <div class="my-4" *ngIf="Trensferring">
                                    <div>
                                        <span class="text-xs">By clicking transfer, you agree to our <a href="#">Terms</a> and that you have read our <a href="#">Privacy Policy</a>.</span>
                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-sm-7" >
                                        <button type="submit" *ngIf="TransferButton" class="btn btn-dark mb-3 mb-sm-0" >transfer</button>
                                        <button type="submit" *ngIf="TransferringButton" class="btn btn-dark mb-3 mb-sm-0" [disabled]="true">uploading... <i class="fas fa-circle-notch fa-spin"></i></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <small class="text-uppercase ls-2 text-muted">what you have to do</small>
                    <h3 class="mt-3" style="color:#93a0b5!important">It’s simple. Upload your images via WeTransfer, Dropbox or Google drive to the ID <a class="text-white" href="mailto:upload@imageingine.com">upload@imageingine.com</a> and download the processed images from the same platform. Everything in between this will be taken care by us.</h3>
                  </div>
                <div class="col-lg-12 pt-4">
                        <p>Our direct upload functionality is under construction and the above mentioned
                            third-party tools are just temporary. Once Imageingine’s parts are built, your uploads
                            and downloads can be done directly via our portal without any third-party tools. We
                            apologize for this inconvenience.</p>
                        <p>Our direct upload functionality is coming SOON!<br> Feel free to mail us with your
                            queries at <a class="text-white" href="mailto:upload@imageingine.com">upload@imageingine.com</a></p>
                </div>
            </div>
        </div>
    </section>
</main>
<app-footer></app-footer>