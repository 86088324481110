import { WINDOW } from '@ng-toolkit/universal';
import { Component, OnInit, Directive , Inject} from '@angular/core';
import * as jQuery from 'jquery';
import { Title, Meta }     from '@angular/platform-browser';
import * as  Globals  from '../global';

@Component({
  selector: 'app-realestate-image-processing',
  templateUrl: './realestate-image-processing.component.html',
  styleUrls: ['./realestate-image-processing.component.css']
})

export class RealestateImageProcessingComponent implements OnInit {
  ImageExtension: any; 
  constructor(@Inject(WINDOW) private window: Window, private titleService: Title,private meta: Meta) {
    this.ImageExtension = Globals.getBrowserExtension();
  }
  ngOnInit() {
    setTimeout(() => {
      $("#REIbody").bind('cut copy paste', function (e) { e.preventDefault(); });
      $("body").on("contextmenu",function(e){ return false; });
      $('#REIbody').on('drop', function() { return false; });
      $('body').bind('keydown', function(event) {
        if(event.ctrlKey && event.shiftKey && event.keyCode==73){ event.preventDefault(); }
        if(event.keyCode==123){ event.preventDefault(); }
        if (event.ctrlKey || event.metaKey) {
          switch (String.fromCharCode(event.which).toLowerCase()) {
          case 's':
            event.preventDefault();
            break;
          case 'f':
            event.preventDefault();
            break;
          case 'g':
            event.preventDefault();
            break;
          }
        }
      });
    }, 500);
  }
}
