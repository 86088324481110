import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  constructor() { }

  ngOnInit() {
    $("#REIbody").unbind('cut copy paste', function (e) { e.preventDefault(); });
    $('body').unbind();
    this.loadScript();
  }

  public loadScript() {
    let url = "../../assets/js/custom.js";
    let body = <HTMLDivElement> document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }
}
