import { Component, OnInit } from '@angular/core';
import { Title, Meta }     from '@angular/platform-browser';
import * as  Globals  from '../global';
@Component({
  selector: 'app-ecommerce-image-processing',
  templateUrl: './ecommerce-image-processing.component.html',
  styleUrls: ['./ecommerce-image-processing.component.css']
})
export class EcommerceImageProcessingComponent implements OnInit {
  ImageExtension: any;
  constructor(private titleService: Title,private meta: Meta) { 
    this.ImageExtension = Globals.getBrowserExtension();
  }

  ngOnInit() {
    // this.titleService.setTitle("E-commerce Image Processing");
  }

}
