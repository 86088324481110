<app-header></app-header>
<main>
    <!-- Spotlight -->
    <section class="sice slice-lg bg-gradient-primary pb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 py-md">
                    <div class="">
                        <h2 class="display-4 font-weight-700 text-white mb-4 lh-110">Our services</h2>
                        <div class="row">
                            <div class="col-lg-10">
                                <p class="lead text-white lh-180">Build your reputation with us. We serve the whole gamut of photo and video editing, with high-quality and client satisfaction being our strong suit.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="slice slice-lg">
        <div class="container">
            <div class="row row-grid">
                <div class="col-lg-6">
                    <a [routerLink]="['/automobile-retouching']" class="card border-0" data-animate-hover="1">
                        <div class="animate-this">
                            <img class="rounded z-depth-3 img-fluid" src="../assets/img/services/automobile-retouching-thumb.{{ImageExtension}}">
                            <span class="mask bg-dark opacity-0"></span>
                            <span class="mask mask--hover"></span>
                            <div class="card-img-overlay d-flex">
                                <div class="col pt-2" style="opacity: 0;">
                                    <h4 class="h3 text-white text-capitalize mb-1">Automobile Retouching</h4>
                                    <h6 class="text-white">Image editing</h6>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-lg-6">
                    <a [routerLink]="['/photo-restoration']" class="card border-0" data-animate-hover="1">
                        <div class="animate-this">
                            <img class="rounded z-depth-3 img-fluid" src="../assets/img/services/old-photo-restoration-thumb.{{ImageExtension}}">
                            <span class="mask bg-dark opacity-0"></span>
                            <span class="mask mask--hover"></span>
                            <div class="card-img-overlay d-flex">
                                <div class="col pt-2" style="opacity: 0;">
                                    <h4 class="h3 text-white text-capitalize mb-1">Photo Restoration</h4>
                                    <h6 class="text-white">Image editing</h6>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <!-- <div class="row row-grid">
                <div class="col-lg-6">
                    <a [routerLink]="['/photo-manipulation']" class="card border-0" data-animate-hover="1">
                        <div class="animate-this">
                            <img class="rounded z-depth-3 img-fluid" src="../assets/img/prv/img-3-800x600.{{ImageExtension}}">
                            <span class="mask bg-dark opacity-3"></span>
                            <span class="mask mask--hover bg-gradient-primary alpha-4"></span>
                            <div class="card-img-overlay d-flex">
                                <div class="col pt-2">
                                    <h4 class="h3 text-white text-capitalize mb-1">Photo Manipulation</h4>
                                    <h6 class="text-white">Image editing</h6>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-lg-6">
                    <a [routerLink]="['/image-enhancement']" class="card border-0" data-animate-hover="1">
                        <div class="animate-this">
                            <img class="rounded z-depth-3 img-fluid" src="../assets/img/prv/img-1-800x600.{{ImageExtension}}">
                            <span class="mask bg-dark opacity-3"></span>
                            <span class="mask mask--hover bg-gradient-primary alpha-4"></span>
                            <div class="card-img-overlay d-flex">
                                <div class="col pt-2">
                                    <h4 class="h3 text-white text-capitalize mb-1">Image Enhancement</h4>
                                    <h6 class="text-white">Image editing</h6>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div> -->
            <div class="row row-grid">
                <div class="col-lg-6">
                    <a [routerLink]="['/realestate-image-processing']" class="card border-0" data-animate-hover="1">
                        <div class="animate-this">
                            <img class="rounded z-depth-3 img-fluid" src="../assets/img/services/real-estate-image-editing-thumb.{{ImageExtension}}">
                            <span class="mask bg-dark opacity-0"></span>
                            <span class="mask mask--hover"></span>
                            <div class="card-img-overlay d-flex">
                                <div class="col pt-2"  style="opacity: 0;">
                                    <h4 class="h3 text-white mb-1">real estate image processing</h4>
                                    <h6 class="text-white">image editing</h6>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-lg-6">
                    <a [routerLink]="['/beauty-portrait-retouching']"  class="card border-0" data-animate-hover="1">
                        <div class="animate-this">
                            <img class="rounded z-depth-3 img-fluid" src="../assets/img/services/beauty-portrait-retouching-thumb.{{ImageExtension}}">
                            <span class="mask bg-dark opacity-0"></span>
                            <span class="mask mask--hover "></span>
                            <div class="card-img-overlay d-flex">
                                <div class="col pt-2"  style="opacity: 0;">
                                    <h4 class="h3 text-white text-capitalize mb-1">Beauty & Portrait Retouching</h4>
                                    <h6 class="text-white">Image editing</h6>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="row row-grid">
                <div class="col-lg-6">
                    <a [routerLink]="['/ecommerce-image-processing']" class="card border-0" data-animate-hover="1">
                        <div class="animate-this">
                            <img class="rounded z-depth-3 img-fluid" src="../assets/img/services/e-commerce-image-processing-thumb.{{ImageExtension}}">
                            <span class="mask bg-dark opacity-0"></span>
                            <span class="mask mask--hover"></span>
                            <div class="card-img-overlay d-flex">
                                <div class="col pt-2"  style="opacity: 0;">
                                    <h4 class="h3 text-white text-capitalize mb-1">E-commerce Image Processing</h4>
                                    <h6 class="text-white">Image editing</h6>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <!-- <div class="col-lg-6">
                    <a [routerLink]="['/image-clipping-path']" class="card border-0" data-animate-hover="1">
                        <div class="animate-this">
                            <img class="rounded z-depth-3 img-fluid" src="../assets/img/prv/img-1-800x600.{{ImageExtension}}">
                            <span class="mask bg-dark opacity-3"></span>
                            <span class="mask mask--hover bg-gradient-primary alpha-4"></span>
                            <div class="card-img-overlay d-flex">
                                <div class="col pt-2">
                                    <h4 class="h3 text-white text-capitalize mb-1">Image Clipping Path</h4>
                                    <h6 class="text-white">Image editing</h6>
                                </div>
                            </div>
                        </div>
                    </a>
                </div> -->
            </div>
        </div>
    </section>
</main>
<app-footer></app-footer>