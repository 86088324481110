<app-header></app-header>
<main ondragstart="return false;" ondrop="return false;">
    <!-- Spotlight -->
    <div id="ICPbody">
        <section class="slice slice-lg bg-cover bg-size--cover overflow-hidden" style="background-position: center; background-image: url(/assets/img/backgrounds/beauty-portrait-retouching-banner.jpg);">
            <span class="mask bg-gradient-success opacity-9"></span>
            <div class="section-inner bg-gradient-primary">
                <span class="section-inner-bg"></span>
            </div>
            <div class="container py-sm">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                            <div class="pt-lg-lg pb-lg-md">
                        <h1 class="heading display-4 font-weight-300 text-white lh-110 mb-4">clipping path services</h1>
                        <p class="lead text-white lh-180">we create the perfect cut for the perfect ad. our team’s passion in this field will make all the difference in your processed images.</p>
                        <div class="mt-5">
                            <a href="#gallery" class="btn btn-white rounded-pill btn-circle btn-sm btn-translate--hover btn-icon mr-sm-4 scroll-me" data-scroll-to-offset="0">
                                <span class="btn-inner--text">View gallery</span>
                                <span class="btn-inner--icon">
                                    <i class="fas fa-angle-right"></i>
                                </span>
                            </a>
                            <a [routerLink]="['/upload-now']" class="btn rounded-pill btn-outline-white btn-icon btn-circle btn-sm px-4">
                                    <span class="btn-inner--icon"><i class="fas fa-play"></i></span>
                                    <span class="btn-inner--text">try us for free</span>
                                </a>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="slice slice-lg bg-secondary">
            <div class="container">
                <div class="row row-grid ">
                    <div class="col-md-12 col-lg-12  ml-lg-auto ">
                        <div class="pr-md-4">
                            <h1 class="heading h1">clipping path services</h1>
                            <p class="lead text-gray my-4">most industries invest a substantial portion into online and offline marketing. the images used in these marketing efforts are what contribute to a business’s brand building and reputation. the neat and clear images with high quality are the ones that create a strong imprint in the minds of end customers. imageingine offers image clipping services to remove any disturbances from the shots and enhance them to produce appealing pictures. no matter the background of a product shot, we can efficiently remove the background to give you a transparent result. we lay much focus on precise and accurate cutting of the product or object from it’s background. this type of e-commerce image preparation requires the investment of great time and effort, which is our department of specialty. we provide you with clipping path services for a wide range of industries from fashion to pharma.</p>
                            <p><b>these services are provided for:</b></p>
                            <ul><li>e-commerce shots</li><li>shots of a wide spectrum of products</li><li>real estate images</li><li>food images</li><li>fashion shots and much more</li></ul>
                        </div>
                    </div>
                </div>
            </div>
            <a href="#gallery" class="tongue tongue-bottom scroll-me"><i class="fas fa-angle-down"></i></a>
        </section>
        <!-- Image + Content -->
        <section class="slice slice-xl bg-white" id="gallery">
            <div class="container">
                <div class="row row-grid justify-content-center">
                    <div class="col-md-12 col-lg-12  ml-lg-auto ">
                        <div class="pr-md-4">
                            <h3 class="heading h1">our edits</h3>
                            <p class="lead text-gray my-4">we create the perfect cut for the perfect ad. our team’s passion in this field will make all the difference in your processed images.</p>
                        </div>
                    </div>
                </div>
                    <div class="code-example">
                        we will update soon...
                    </div>
            </div>
        </section>
        <app-service-features></app-service-features>
    </div>
</main>
<app-footer></app-footer>