<app-header></app-header>
<main ondragstart="return false;" ondrop="return false;">
    <!-- Spotlight -->
    <div id="PRbody">
        <section class="slice slice-lg bg-cover bg-size--cover overflow-hidden" style="background-position: center; background-image: url(/assets/img/backgrounds/photo-restoration.jpg);">
            <span class="mask bg-gradient-dark opacity-7"></span>
            <div class="section-inner bg-gradient-primary">
                <span class="section-inner-bg"></span>
            </div>
            <div class="container py-sm">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                            <div class="pt-lg-lg pb-lg-md">
                        <h1 class="heading display-4 font-weight-300 text-white lh-110 mb-4">photo restoration</h1>
                        <p class="lead text-white lh-180">old ones just like new, you’ll never realise it. our team’s dedication will restore a photo in any condition to look like a new one.</p>
                        <div class="mt-5">
                            <a href="#gallery" class="btn btn-white btn-circle btn-sm btn-translate--hover btn-icon mr-sm-4 scroll-me" data-scroll-to-offset="0">
                                <span class="btn-inner--text">View gallery</span>
                                <span class="btn-inner--icon">
                                    <i class="fas fa-angle-right"></i>
                                </span>
                            </a>
                            <a [routerLink]="['/upload-now']" class="btn btn-outline-white btn-icon btn-circle btn-sm px-4">
                                    <span class="btn-inner--icon"><i class="fas fa-play"></i></span>
                                    <span class="btn-inner--text">try us for free</span>
                                </a>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="slice slice-lg bg-secondary">
            <div class="container">
                <div class="row row-grid ">
                    <div class="col-md-12 col-lg-12  ml-lg-auto ">
                        <div class="pr-md-4">
                            <h1 class="heading h1">photo restoration</h1>
                            <p class="lead text-gray my-4">among inheritances, the photographs are the ones which best preserve memories and bring a smile to your face every time you look at them. these irreplaceable photographs sometimes fade with time because of various chemical reactions. they could tear, become blotchy, could acquire stains or have fold lines. we at imageingine are experts at restoring damaged and old photos and give you back one that you can preserve over time, just like a new one. we can even enhance the picture to meet your tastes, without losing the originality of the photo. </p>
                            <p><b>some of our photo restoration services include:</b></p>
                            <ul><li>eliminate blotches, stains, spots</li><li>fix fold lines, scratches, tears</li><li>fix any colour fading</li><li>restore the sharpness and bring back focus</li><li>and reconstruct missing parts among many other fixes</li></ul>
                        </div>
                    </div>
                </div>
            </div>
            <a href="#gallery" class="tongue tongue-bottom scroll-me"><i class="fas fa-angle-down"></i></a>
        </section>
        <!-- Image + Content -->
        <section class="slice slice-xl bg-white" id="gallery">
            <div class="container">
                <div class="row row-grid justify-content-center">
                    <div class="col-md-12 col-lg-12  ml-lg-auto ">
                        <div class="pr-md-4">
                            <h3 class="heading h1">our edits</h3>
                            <p class="lead text-gray my-4">old ones just like new, you’ll never realise it. our team’s dedication will restore a photo in any condition to look like a new one.</p>
                        </div>
                    </div>
                </div>
                    <div class="code-example">
                        we will update soon...
                    </div>
            </div>
        </section>
        <app-service-features></app-service-features>
    </div>
</main>
<app-footer></app-footer>