<app-header></app-header>
<main>
    <!-- Spotlight -->
    <section class="slice bg-cover bg-size--cover" style="background-image: url('../assets/img/backgrounds/img-8.jpg');">
        <span class="mask bg-gradient-primary alpha-9"></span>
        <div class="container d-flex align-items-center px-0 py-md">
            <div class="col">
                <div class="row row-grid">
                    <div class="col-lg-8">
                        <div class="py-5">
                            <h1 class="text-white mb-4">Be it for a commercial video or a festive one filled with emotions, we will edit it to perfection, meeting your target.</h1>
                            <p class="lead text-white lh-180">For that beautiful video that creates a lasting impression, our focus on detail makes all the difference. Our small yet efficient team pride themselves in committing to each project with individualized attention, creating remarkable differences.</p>
                            <!-- <a data-fancybox class="btn btn-white btn-icon-only shadow btn-zoom--hover mt-4">
                                <span class="btn-inner--icon"><i class="fas fa-play"></i></span>
                            </a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="slice slice-lg bg-secondary">
        <div class="container">
            <div class="mb-lg text-center">
                <h3>Video Editing Services</h3>
                <div class="fluid-paragraph text-center mt-4">
                    <p class="lead lh-180">At Imageingine, we do not restrict ourselves to just photos. Bring us your videos and we will clip, edit and process it to perfection. Alternately, we also undertake video shoots for various sectors. Our services in the spectrum of video shooting and editing include:</p>
                    <div class="code-example">
                        <span class="badge badge-lg badge-pill badge-white text-uppercase">Video clipping</span>
                        <span class="badge badge-lg badge-pill badge-white text-uppercase">Corporate videos</span>
                        <span class="badge badge-lg badge-pill badge-white text-uppercase">Real estate video tour</span>
                        <span class="badge badge-lg badge-pill badge-white text-uppercase">Holiday videos</span>
                        <span class="badge badge-lg badge-pill badge-white text-uppercase">Video brochures</span>
                        <span class="badge badge-lg badge-pill badge-white text-uppercase">Product videos</span>
                        <span class="badge badge-lg badge-pill badge-white text-uppercase">Wedding video editing</span>

                        
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- <section class="slice slice-lg">
        <div class="container">
            <div class="mb-md text-center">
                <h3 class="heading h2">Our Edited Videos</h3>
                <div class="fluid-paragraph mt-3">
                    <p class="lead">Be it for a commercial video or a festive one filled with emotions, we will edit it to perfection, meeting your target.</p>
                </div>
            </div>
            <div class="row row-grid">
                <div class="col-lg-6">
                    <div class="card border-0" data-animate-hover="1">
                        <div class="animate-this">
                            <img class="rounded z-depth-3 img-fluid" src="../assets/img/prv/img-6-800x600.jpg">
                            <a href="https://www.youtube.com/watch?v=mqpwDJpI3Rc" class="btn btn-lg btn-white btn-icon-only btn-zoom--hover rounded-circle shadow-sm position-absolute right-4 top-4" data-fancybox="">
                                <span class="btn-inner--icon"><i class="fas fa-play"></i></span>
                            </a>
                        </div>
                        <div class="pt-4">
                            <blockquote class="blockquote">
                                <span class="quote"></span>
                                <div class="quote-text">
                                    <p class="font-italic lh-170">Amazing template! All-in-one, clean code, beautiful design, and really not hard to work with.Highly recommended for any kind on website.</p>
                                    <footer class="blockquote-footer">Someone famous in <cite title="Source Title">Danielle Levin</cite>
                                    </footer>
                                </div>
                            </blockquote>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="card border-0" data-animate-hover="1">
                        <div class="animate-this">
                            <img class="rounded z-depth-3 img-fluid" src="../assets/img/prv/img-7-800x600.jpg">
                            <a href="https://www.youtube.com/watch?v=mqpwDJpI3Rc" class="btn btn-lg btn-white btn-icon-only btn-zoom--hover rounded-circle shadow-sm position-absolute right-4 top-4" data-fancybox="">
                                <span class="btn-inner--icon"><i class="fas fa-play"></i></span>
                            </a>
                        </div>
                        <div class="pt-4">
                            <blockquote class="blockquote">
                                <span class="quote"></span>
                                <div class="quote-text">
                                    <p class="font-italic lh-170">Amazing template! All-in-one, clean code, beautiful design, and really not hard to work with.Highly recommended for any kind on website.</p>
                                    <footer class="blockquote-footer">Someone famous in <cite title="Source Title">Danielle Levin</cite>
                                    </footer>
                                </div>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </section> -->
</main>
<app-footer></app-footer>