<app-header></app-header>
<main ondragstart="return false;" ondrop="return false;">
    <!-- Spotlight -->
    <div id="EIPbody">
        <section class="slice slice-lg bg-cover bg-size--cover overflow-hidden" style="background-position: center; background-image: url(/assets/img/backgrounds/ecommerce-image-processing-banner.jpg);">
            <span class="mask bg-gradient-primary opacity-9"></span>
            <div class="section-inner bg-gradient-primary">
                <span class="section-inner-bg"></span>
            </div>
            <div class="container py-sm">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                            <div class="pt-lg-lg pb-lg-md">
                        <h1 class="heading display-4 font-weight-300 text-white lh-110 mb-4">e-commerce image processing</h1>
                        <p class="lead text-white lh-180">our processed images will be so appealing that the customers will want to buy as soon as they see it. we deliver such client-ready images.</p>
                        <div class="mt-5">
                            <a href="#gallery" class="btn btn-white rounded-pill btn-circle btn-sm btn-translate--hover btn-icon mr-sm-4 scroll-me" data-scroll-to-offset="0">
                                <span class="btn-inner--text">View gallery</span>
                                <span class="btn-inner--icon">
                                    <i class="fas fa-angle-right"></i>
                                </span>
                            </a>
                            <a [routerLink]="['/upload-now']" class="btn rounded-pill btn-outline-white btn-icon btn-circle btn-sm px-4">
                                    <span class="btn-inner--icon"><i class="fas fa-play"></i></span>
                                    <span class="btn-inner--text">try us for free</span>
                                </a>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="slice slice-lg bg-secondary">
            <div class="container">
                <div class="row row-grid ">
                    <div class="col-md-12 col-lg-12  ml-lg-auto ">
                        <div class="pr-md-4">
                            <h1 class="heading h1">e-commerce image processing</h1>
                            <p class="lead text-gray my-4">online shopping and e-commerce sites are booming right now. the main element for driving sales of your product is the product image itself that is displayed on multiple websites and apps. since customers cannot feel the physical product, the image should be able to convince them to click on the ‘buy now’ button. the image quality, the ability of the image to convey the feel and grade of the original product, and the first impression it creates on the customers’ mind, all contribute to enticing them. this is why the product images used online should go through extensive e-commerce image preparation. </p>
                            <p><b>our services majorly include:</b></p>
                            <ul><li>e-commerce image editing for any product</li><li>clipping path for product shots</li></ul>
                        </div>
                    </div>
                </div>
            </div>
            <a href="#gallery" class="tongue tongue-bottom scroll-me"><i class="fas fa-angle-down"></i></a>
        </section>
        <!-- Image + Content -->
        <section class="slice slice-xl bg-white" id="gallery">
            <div class="container">
                <div class="row row-grid justify-content-center">
                    <div class="col-md-12 col-lg-12  ml-lg-auto ">
                        <div class="pr-md-4">
                            <h3 class="heading h1">our edits</h3>
                            <p class="lead text-gray my-4">our processed images will be so appealing that the customers will want to buy as soon as they see it. we deliver such client-ready images.</p>
                        </div>
                    </div>
                </div>
                    <div class="code-example">
                        we will update soon...
                    </div>
            </div>
        </section>
        <app-service-features></app-service-features>
    </div>
</main>
<app-footer></app-footer>