/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./coming-soon.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./coming-soon.component";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common/http";
import * as i5 from "@angular/platform-browser";
var styles_ComingSoonComponent = [i0.styles];
var RenderType_ComingSoonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ComingSoonComponent, data: {} });
export { RenderType_ComingSoonComponent as RenderType_ComingSoonComponent };
export function View_ComingSoonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "html", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "body", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "CenterText"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Coming Soon..."]))], null, null); }
export function View_ComingSoonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-coming-soon", [], null, null, null, View_ComingSoonComponent_0, RenderType_ComingSoonComponent)), i1.ɵdid(1, 114688, null, 0, i2.ComingSoonComponent, [i3.Router, i4.HttpClient, i3.ActivatedRoute, i5.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ComingSoonComponentNgFactory = i1.ɵccf("app-coming-soon", i2.ComingSoonComponent, View_ComingSoonComponent_Host_0, {}, {}, []);
export { ComingSoonComponentNgFactory as ComingSoonComponentNgFactory };
